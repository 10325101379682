// NOTE: This has a high probability of being turned into a reusable component.
// Will explore the next time I hit an alert
import { Row, Col, Alert } from "react-bootstrap";
import { type Variant } from "react-bootstrap/esm/types";

interface EcrAlertProps {
  type: Variant;
  message: string;
  onClose: () => void;
}

const EcrAlert = ({ type, message, onClose }: EcrAlertProps) => {
  return (
    <Row className="d-flex justify-content-center mt-3">
      <Col lg={10}>
        <Alert variant={type} dismissible onClose={() => onClose()}>
          {message}
        </Alert>
      </Col>
    </Row>
  );
};
export default EcrAlert;
