import { baseApi } from "api/BaseApi";
import { TechnicalDecision } from "api/memberPortal/technicalDecisions/technicalDecisions.types";

import { EcrTdPayload, EcrTdResponse } from "../ecr.types";

import { EcrTq } from "./ecrTds.types";

const ECR_BASE_URL = "ecr";
const ECR_TD_URL = "ecr/technical-decision";

const ecrTdsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getEcrTdByEcrIdAndTdId: build.query({
      query: ({ ecrid, tdid }) => ({
        url: `${ECR_TD_URL}/get_ecr_td_by_ecrid_and_tdid/`,
        params: { ecrid, tdid },
      }),
    }),

    getAllTdsForEcr: build.query<TechnicalDecision[], number | string>({
      query: (ecrid) => ({
        url: `${ECR_BASE_URL}/technical_decisions_by_ecrid/`,
        params: { ecrid },
      }),
      providesTags: ["ECR_TDS"],
    }),

    // This may be an outdated concept on our site
    // I don't think we can actually make new ones in our system
    // There are only 8 TOTAL records for this model
    // However they do populate on prod where connected so I made the endpoint
    getAllTqsForEcr: build.query<EcrTq[], number | string>({
      query: (ecrid) => ({
        url: `${ECR_BASE_URL}/technical_queries_by_ecrid/`,
        params: { ecrid },
      }),
      providesTags: ["ECR_TQS"],
    }),

    createEcrTd: build.mutation<EcrTdResponse, EcrTdPayload>({
      query: (body) => ({
        url: `${ECR_TD_URL}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ECR_TDS"],
    }),
    // TODO: reevaluate the response type

    deleteEcrTd: build.mutation({
      query: (xid) => ({
        url: `${ECR_TD_URL}/${xid}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ECR_TDS"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useCreateEcrTdMutation,
  useGetAllTdsForEcrQuery,
  useGetAllTqsForEcrQuery,
  useLazyGetEcrTdByEcrIdAndTdIdQuery,
  useDeleteEcrTdMutation,
} = ecrTdsApi;
