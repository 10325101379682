import parse from "html-react-parser";
import moment from "moment";

import { Ecr } from "api/memberPortal/ecr/ecr.types";

interface EcrResponseProps {
  ecr?: Ecr;
}

const EcrResponse = ({ ecr }: EcrResponseProps) => {
  if (!ecr || !ecr.response) return;
  const { responseauthor, responsedtg } = ecr;
  const displayDate = responsedtg
    ? moment.utc(responsedtg).format("YYYY.MM.DD [at] HHMM")
    : "";

  return (
    <>
      <h5 className="fw-bold mt-3 mb-3">LAB RESPONSE</h5>

      <p className="mx-3">{ecr?.response && parse(ecr?.response)}</p>

      <p className="mx-3">
        {`RESPONSE SUBMITTED: ${responseauthor?.first_name} ${responseauthor?.last_name} ${displayDate}`}
      </p>
    </>
  );
};
export default EcrResponse;
