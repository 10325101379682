import { Box } from "@mui/material";
import parse from "html-react-parser";
import { useState } from "react";
import { Button } from "react-bootstrap";

import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import HTMLEditor from "components/UI/HTMLEditor";
import { HtmlEditorChangeEvent } from "components/UI/HTMLEditor/HtmlEditor.types";

interface EditableHtmlProps {
  title: string;
  editText: string;
  saveText: string;
  allowEdit: boolean;
  value: string;
  onSave: TriggerFunction<string, Ecr>;
}

const EditableHtml = ({
  title,
  editText,
  saveText,
  allowEdit,
  value,
  onSave,
}: EditableHtmlProps) => {
  const [currentHtml, setCurrentHtml] = useState(value);
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      {title && <h5 className="fw-bold mt-3 mb-3">{title}</h5>}
      {isEditing ? (
        <>
          {/* @ts-ignore - underlying component is not properly typed yet */}
          <HTMLEditor
            name={`html-${title}`}
            handleChange={(e: HtmlEditorChangeEvent) =>
              setCurrentHtml(e.target.value)
            }
            defaultValue={value}
            setIsValid={() => {}}
            isValid={true}
          />

          <div className="text-end">
            <Button
              variant="danger"
              onClick={async () => {
                setIsEditing(false);
              }}
              className="m-2 mt-3"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                await onSave(currentHtml);
                setIsEditing(false);
              }}
              className="m-2 mt-3 mx-0"
            >
              {saveText}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Box className="mx-3">{parse(value)}</Box>
          {allowEdit && (
            <div className="text-end">
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => setIsEditing(true)}
              >
                {editText}
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditableHtml;
