import { TextField } from "@mui/material";
import { useState } from "react";

import { TriggerFunction } from "api/common/types/response.types";
import { Ecr, EcrUpdatePayload } from "api/memberPortal/ecr/ecr.types";
import BaseEditableField from "components/DetailsComponents/EditableFields/BaseEditableField";

interface EcrTitleProps {
  name: string;
  onSave: TriggerFunction<Partial<EcrUpdatePayload>, Ecr>;
  allowEdit: boolean;
}

const EcrTitleField = ({ name, onSave, allowEdit }: EcrTitleProps) => {
  const [currentValue, setCurrentValue] = useState(name);

  return (
    <BaseEditableField
      allowEdit={allowEdit}
      title={"Title"}
      value={name}
      EditComponent={
        <TextField
          variant="filled"
          fullWidth
          defaultValue={name}
          margin="none"
          size="small"
          hiddenLabel
          onBlur={(e) => setCurrentValue(e.target.value)}
        />
      }
      onSave={() => onSave({ gist: currentValue })}
      editButtonSize="medium"
      sx={{
        flex: {
          md: "1 1 50%",
          sm: "1 1 100%",
        },
        maxWidth: {
          md: "calc(50% - 9px)", // 8 Margin Right + 1 Left Border
          sm: "100%",
        },
        alignItems: "flex-start",
        margin: 1,
        marginLeft: 0,
      }}
      displaySx={{
        color: "#0dcaf0",
        fontSize: "24px",
        fontWeight: 700,
        width: "100%",
        textAlign: "left",
        margin: 0,
      }}
    />
  );
};
export default EcrTitleField;
