import { ChangeEvent, FormEvent, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { useCreateEarMutation } from "api/memberPortal/ear/ear.api";
import { EarFileData, EarFormFields } from "api/memberPortal/ear/ear.types";
import { useGetAllProductsQuery } from "api/memberPortal/project/product/product.api";
import ReactSelect from "components/ReactSelect";
import HTMLEditor from "components/UI/HTMLEditor";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

interface AddEarFormProps {
  product_id?: string;
  show: boolean;
  onHide: () => void;
}
type AprioriOption = "yesYes" | "yesNo" | "noNo";

const initialFileValues = {
  file_type: "ear-review",
  file_source_type: "Check In",
  file_display_name: "Entropy Assessment Report (EAR)",
  uploaded_on: "",
  file_name: "",
  file_label: "",
  active: true,
  file: undefined,
  private: true,
} as EarFileData;

const initialFormValues = {
  apriori: false,
  reqd: true,
  blurba: "",
  just: "",
  ear_file_label: "",
  tier3: true,
} as EarFormFields;

const AddEarForm = ({ product_id, show, onHide }: AddEarFormProps) => {
  const [earFile, setEarFile] = useState<EarFileData>(initialFileValues);
  const [ear, setEar] = useState<EarFormFields>(initialFormValues);
  const [productId, setProductId] = useState(product_id);
  const [htmlValid, setHtmlValid] = useState(false);
  const [enforceFocusModal, setEnforceFocusModal] = useState(true);
  const { currentUser } = useAuth();
  const { hideLoadingSpinner, showLoadingSpinner } = useLoading();

  const [triggerCreateEar] = useCreateEarMutation();

  const { data: products = [] } = useGetAllProductsQuery(
    { in_eval: true },
    { skip: !show },
  );

  const productOptions = products.map(
    (item: { product_id: number; v_id: number }) => ({
      value: item.product_id,
      label: "VID" + item.v_id,
    }),
  );

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    const newEarFile = {
      uploaded_on: getISOCurrentDate(),
      file_name: file.name,
      file_label: ear.ear_file_label,
      file: file,
    };
    setEarFile({ ...earFile, ...newEarFile });
  };

  const handleAprioriChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lookup = {
      yesYes: { apriori: true, tier3: true },
      yesNo: { apriori: true, tier3: false },
      noNo: { apriori: false, tier3: false },
    };

    const value = e.target.value as AprioriOption;

    setEar({ ...ear, ...lookup[value] });
  };

  const handleReqdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const lookup = {
      yes: true,
      no: false,
    };

    const newValue = lookup[e.target.value as "yes" | "no"];
    setEar({ ...ear, reqd: newValue });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEar({ ...ear, [e.target.name]: e.target.value });
  };

  const handleAddEar = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!htmlValid) return;

    showLoadingSpinner("Adding EAR Review...");
    // @ts-ignore TODO: remove once user type merged in
    await triggerCreateEar({ ear, earFile, currentUser, productId });
    hideLoadingSpinner();
    onHide();
  };

  const removeFile = () => {
    setEarFile(initialFileValues);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      enforceFocus={enforceFocusModal}
    >
      <Form onSubmit={handleAddEar}>
        <Modal.Header closeButton>
          <h4 className="mb-0">Add EAR Review</h4>
        </Modal.Header>
        {!product_id && (
          <Row className="p-3">
            <Col>
              <Form.Label className="fw-bold">Associated Product *</Form.Label>
              {/* @ts-ignore : NOTE: REACT SELECT PROPS ARE NOT PROPERLY TYPED */}
              <ReactSelect
                id="product-select"
                options={productOptions}
                isValid={true}
                name="product-select"
                newObj={{ "product-select": null }}
                // NOTE: This is not actualy an HTML ChangeEvent
                // This is a custom message from our ReactSelect component
                // It only has a target prop with a name and a value
                handleChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setProductId(e.target.value);
                }}
              />
            </Col>
          </Row>
        )}
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={6}>
                <Form.Group
                  controlId="apriori"
                  onChange={handleAprioriChange}
                  defaultValue="yesYes"
                >
                  <Form.Label className="fw-bold">
                    This EAR was previously approved?
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    label="Yes, but Additional Review by ERT Required"
                    id="apriori-yes-tier3-yes"
                    name="apriori"
                    value="yesYes"
                    defaultChecked
                  />

                  <Form.Check
                    type="radio"
                    label="Yes, but No Additional Review Required"
                    id="apriori-yes-tier3-no"
                    name="apriori"
                    value="yesNo"
                  />

                  <Form.Check
                    type="radio"
                    label="No"
                    id="apriori-no-tier3-no"
                    name="apriori"
                    value="noNo"
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group
                  controlId="reqd"
                  onChange={handleReqdChange}
                  defaultValue="yes"
                >
                  <Form.Label className="fw-bold">
                    This EAR is required for the Evaluation?
                  </Form.Label>

                  <Form.Check
                    type="radio"
                    label="Yes"
                    id="reqd-yes"
                    name="reqd"
                    value="yes"
                    defaultChecked
                  />

                  <Form.Check
                    type="radio"
                    label="No"
                    id="reqd-no"
                    name="reqd"
                    value="no"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Row>
                <p className="fw-bold form-title">ATTACH EAR FILE</p>
              </Row>

              <Row>
                <Col xl={6} sm={12}>
                  <Form.Group controlId="ear_file_label">
                    <Form.Label className="small text-secondary">
                      File Label
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="ear_file_label"
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>

                <Col lg={6} sm={12}>
                  <Form.Group controlId="ear_file">
                    <Form.Label className="small text-secondary">
                      Attach EAR Files
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="ear_file"
                      onChange={handleFileChange}
                      disabled={!ear.ear_file_label}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Row>

            <Container className="mt-4">
              {earFile.file && (
                <Row className="m-3 border-bottom text-center">
                  <Col sm={5}>{earFile.file_display_name}</Col>
                  <Col sm={5}>{earFile.file_label}</Col>
                  <Col sm={2}>
                    <Button
                      variant="outline-primary"
                      className="attachment-remove"
                      onClick={() => removeFile()}
                    >
                      X
                    </Button>
                  </Col>
                </Row>
              )}
            </Container>

            <Row className="mb-3 p-0">
              <Form.Label className="mt-3 fw-bold">
                {ear.reqd
                  ? "Initial Comments: *"
                  : "Justification for No EAR Required: *"}
              </Form.Label>
              <HTMLEditor
                name={ear.reqd ? "blurba" : "just"}
                handleChange={handleChange}
                setIsValid={setHtmlValid}
                isValid={htmlValid}
                setEnforceFocusModal={setEnforceFocusModal}
                defaultValue=""
                disabled={false}
              />
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button type="submit" disabled={!htmlValid}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEarForm;
