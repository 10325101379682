import { baseApi } from "api/BaseApi";

import {
  Product,
  ProductStub,
  ProductMetrics,
  CCTLProductMetrics,
} from "./product.types";

const PRODUCT_BASE_QUERY = "/project/product";

interface GetProductsQueryParams {
  in_eval?: boolean;
  without_nist?: boolean;
}

const productApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProduct: build.query<Product, number>({
      query: (id) => `${PRODUCT_BASE_QUERY}/${id}/`,
    }),

    getProductByVid: build.query<Product, number | string>({
      query: (vid) => ({
        url: `${PRODUCT_BASE_QUERY}/get_product_by_vid/`,
        params: { vid },
      }),
    }),

    getAllProducts: build.query<ProductStub[], GetProductsQueryParams | void>({
      query: (params) => {
        const queryParams = params
          ? { in_eval: params.in_eval, without_nist: params.without_nist }
          : {};
        return {
          url: `${PRODUCT_BASE_QUERY}/get_all_products/`,
          params: queryParams,
        };
      },
    }),

    getValidatorProducts: build.query<ProductStub[], void>({
      query: () => `${PRODUCT_BASE_QUERY}/all_my_validations/`,
    }),

    getProductMetrics: build.query<ProductMetrics, void>({
      query: () => `${PRODUCT_BASE_QUERY}/product_metrics/`,
    }),

    getCCTLProductMetrics: build.query<CCTLProductMetrics, void>({
      query: () => `${PRODUCT_BASE_QUERY}/cctl_product_metrics/`,
    }),

    getCCTLProductsAvailableForAM: build.query<Product[], void>({
      query: () => `${PRODUCT_BASE_QUERY}/my_available_am_products/`,
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllProductsQuery,
  useGetProductQuery,
  useGetValidatorProductsQuery,
  useLazyGetProductByVidQuery,
  useGetProductMetricsQuery,
  useGetCCTLProductMetricsQuery,
  useGetCCTLProductsAvailableForAMQuery,
} = productApi;
