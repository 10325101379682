import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import { useState, useEffect, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Stack,
  Accordion,
  Button,
  Alert,
  Form,
  Card,
} from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import Select from "react-select";

import { EAR_STATUSES } from "api/memberPortal/ear/ear.types";
import ERTApproveRejectModal from "components/Dashboard/ProductsModule/ERTApproveRejectModal";
import ReactSelect from "components/ReactSelect";
import DeleteModal from "components/UI/DeleteModal";
import HTMLEditor from "components/UI/HTMLEditor";
import { useLoading } from "components/UI/LoadingSpinner/UseLoading";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";
import { getCurrentDate } from "utils/timeUtils";

import EarNotes from "../components/EarNotes/EarNotes";
import EarEscalateErtModal from "../components/modals/EarEscalateModal/EarEscalateErt.modal";
import EarResponseModal from "../components/modals/EarResponseModal/EarResponse.modal";
import EarResubmitModal from "../components/modals/EarResubmitModal/EarResubmit.modal";

import EarSideInfo from "./components/EarSideInfo/EarSideInfo";

const earStatusOptions = [
  { label: EAR_STATUSES.PENDING_REVIEW, value: EAR_STATUSES.PENDING_REVIEW },
  { label: EAR_STATUSES.DRAFT, value: EAR_STATUSES.DRAFT },
  {
    label: EAR_STATUSES.COMPLETED_APPROVED_WITH_PROVISIONS,
    value: EAR_STATUSES.COMPLETED_APPROVED_WITH_PROVISIONS,
  },
  {
    label: EAR_STATUSES.COMPLETED_APPROVED_TO_PROCEED,
    value: EAR_STATUSES.COMPLETED_APPROVED_TO_PROCEED,
  },
  {
    label: EAR_STATUSES.COMPLETED_EAR_PREVIOUSLY_APPROVED,
    value: EAR_STATUSES.COMPLETED_EAR_PREVIOUSLY_APPROVED,
  },
  {
    label: EAR_STATUSES.COMPLETED_NO_EAR_REQUIRED,
    value: EAR_STATUSES.COMPLETED_NO_EAR_REQUIRED,
  },
  {
    label: EAR_STATUSES.REJECTED_PENDING_REVISION,
    value: EAR_STATUSES.REJECTED_PENDING_REVISION,
  },
];

const noYesOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export default function CheckPermEARDetails() {
  const { id } = useParams();

  const { authToken } = useAuth();
  const { showLoadingSpinner, hideLoadingSpinner } = useLoading();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (id) {
      showLoadingSpinner("Loading EAR...");
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/is_auth_to_view_ear?ear_id=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          if (response.data?.can_view === true) {
            setIsAuthorized(true);
          } else {
            setIsAuthorized(false);
          }
          setIsInitialized(true);
          hideLoadingSpinner();
        })
        .catch((err) => {
          console.log("unable to get member's virtual teams", err);
          setIsInitialized(true);
          hideLoadingSpinner();
        });
    }
  }, [id]);

  if (!isInitialized) return <></>;

  if (!isAuthorized) return <UnauthorizedView />;
  if (isAuthorized) return <EARDetails />;
}

function EARDetails() {
  const { id } = useParams();
  const [review, setReview] = useState({});
  const [readOnly, setReadOnly] = useState(true);
  const [earPPs, setEarPPs] = useState([]);
  const [showEscalateModal, setShowEscalateModal] = useState(false);
  const [showCompleteTerminateModal, setShowCompleteTerminateModal] =
    useState(false);
  const [ERTMember, setERTMember] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [completeView, setCompleteView] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showResubmitModal, setShowResubmitModal] = useState(false);
  const [isEditing, setIsEditing] = useState("");
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [ertMembers, setERTMembers] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [textFieldUpdate, setTextFieldUpdate] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [canSuperEdit, setSuperEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [doesExists, setDoesExists] = useState(true);
  const [notes, setNotes] = useState([]);

  const { authToken, csrfToken, currentUser, permissions } = useAuth();

  const navigate = useNavigate();

  const showDeleteModal = () => {
    setShowDelete(true);
  };
  const handleCloseDeleteModal = () => {
    setShowDelete(false);
  };

  const handleOpenResubmitModal = () => {
    setShowResubmitModal(true);
  };
  const handleCloseResubmitModal = () => {
    setShowResubmitModal(false);
  };

  const handleSaveAgents = () => {
    let data = {
      pid: review?.pid?.product_id,
      ...selectedAgents,
    };
    axios
      .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`, data, {
        withCredentials: true,
        headers: { Authorization: `Token ${authToken}` },
      })
      .then(() => {
        setIsEditing("");
        setAlert({ message: "Update Successfully Saved!", type: "success" });
        getReview();
      })
      .catch((err) => {
        console.log("Error saving update", err);
        setAlert({ message: "Error saving update.", type: "danger" });
      });
  };

  const handleDeleteEAR = useCallback(() => {
    const deleteEar = {
      ...review,
      status: "Deleted",
      pid: review?.pid?.product_id,
      creator: review?.creator?.id,
      editor: currentUser?.id,
      agent: review?.agent?.id,
      escalator: review?.escalator?.id,
      response: review?.response?.upid,
    };
    axios
      .put(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${review?.earid}/`,
        deleteEar,
        {
          withCredentials: true,
          headers: {
            Authorization: `Token ${authToken}`,
            "X-CSRFToken": csrfToken,
          },
        },
      )
      .then(() => {
        setShowDelete(false);
        navigate("/community/products/details/" + review?.pid?.product_id);
      })
      .catch((error) => {
        console.log("Unable to delete EAR: " + error);
        setDoesExists(false);
      });
  }, [review]);

  const handleChangeTextField = (e) => {
    setTextFieldUpdate({ ...textFieldUpdate, [e.target.name]: e.target.value });
  };

  const handleChange = (e) => {
    let data = {
      [e.target.name]: e.target.value,
      pid: review.pid.product_id,
      moddate: getCurrentDate(),
    };

    if (e.target.name === "status") {
      if (
        [EAR_STATUSES.PENDING_REVIEW, EAR_STATUSES.DRAFT]?.includes(
          e.target.value,
        )
      ) {
        data.approved = false;
        data.rejected = false;
        axios
          .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`, data, {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          })
          .then(() => {
            setAlert({
              message: "Update Successfully Saved!",
              type: "success",
            });
            getReview();
          })
          .catch((err) => {
            console.log("Error saving update", err);
            setAlert({ message: "Error saving update.", type: "danger" });
          });
      } else if (e.target.value?.includes("Completed")) {
        setShowCompleteTerminateModal(true);
        setCompleteView(true);
        setSelectedStatus(e.target.value);
      } else {
        setShowCompleteTerminateModal(true);
        setSelectedStatus(e.target.value);
      }
    } else {
      axios
        .put(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`, data, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setAlert({ message: "Update Successfully Saved!", type: "success" });
          getReview();
        })
        .catch((err) => {
          console.log("Error saving update", err);
          setAlert({ message: "Error saving update.", type: "danger" });
        });
    }
    setIsEditing("");
  };

  const getReview = useCallback(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}ear/${id}/`, {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        })
        .then((response) => {
          setReview(response.data);
          getEarPPs(response.data);
        })
        .catch((error) => {
          console.log(error);

          setDoesExists(false);
        });
    }
  }, [id]);

  const getIsErtMember = useCallback(() => {
    if (currentUser) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/is_ert_team_member?user=${currentUser.id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => {
          setERTMember(response.data.is_member);
        })
        .catch((err) =>
          console.log("unable to get member's virtual teams", err),
        );
    }
  }, [currentUser]);

  const getErtMembers = useCallback(() => {
    axios
      .get(
        `${process.env.REACT_APP_DJANGO_ENDPOINT}virtual-team/members/ert_team_members/`,
        {
          withCredentials: true,
          headers: { Authorization: `Token ${authToken}` },
        },
      )
      .then((response) => {
        let members = response.data?.map((member) => {
          return {
            label: `${member?.account_id__first_name} ${member?.account_id__last_name}`,
            value: member.account_id,
          };
        });
        setERTMembers(members);
      })
      .catch((err) => console.log("unable to get ert team members", err));
  }, [currentUser]);

  const getEarPPs = (rev) => {
    if (rev) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}project/protection-profiles/get_ear_pp/?ear=${id}`,
          {
            withCredentials: true,
            headers: { Authorization: `Token ${authToken}` },
          },
        )
        .then((response) => setEarPPs(response.data))
        .catch((error) => console.log(error));
    }
  };

  const fetchNotes = useCallback(() => {
    if (review?.earid) {
      axios
        .get(
          `${process.env.REACT_APP_DJANGO_ENDPOINT}note/get_ear_notes_by_earid/?earid=${review.earid}`,
          {
            withCredentials: true,
            headers: {
              Authorization: `Token ${authToken}`,
            },
          },
        )
        .then((response) => {
          setNotes(response.data);
        })
        .catch((error) => {
          if (
            !error.response.data.note.includes(
              "No notes with those params exist",
            )
          ) {
            console.log(error);
          } else {
            setNotes([]);
          }
        });
    }
  }, [authToken, review?.earid]);

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  useInterval(() => {
    fetchNotes();
  }, 180000); //3 min

  const handleCloseCompleteTerminateModal = () => {
    setCompleteView(false);
    setShowCompleteTerminateModal(false);
    getReview();
  };

  const handleCloseResponseModal = () => {
    setShowResponseModal(false);
    getReview();
  };

  useEffect(() => {
    getReview();
  }, [getReview]);

  useInterval(() => {
    getReview();
  }, 60000); //1 min

  useEffect(() => {
    getIsErtMember();
  }, [getIsErtMember]);

  useInterval(() => {
    getIsErtMember();
  }, 180000); //3 min

  useEffect(() => {
    getErtMembers();
  }, [getErtMembers]);

  useEffect(() => {
    setReadOnly(review?.approved || review?.rejected);
  }, [review.approved, review.rejected]);

  useEffect(() => {
    if (permissions?.role_type === "NIAP" || ERTMember) {
      setUserCanEdit(true);
      setSuperEdit(true);
    } else if (permissions?.role_type === "Validator") {
      setUserCanEdit(true);
    } else {
      setUserCanEdit(false);
      setSuperEdit(false);
    }
  }, [ERTMember, permissions.role_type]);

  const canViewPage = permissions.role_permissions.find(
    (permission) => permission.area === "EAR Reviews",
  )?.read;

  if (!canViewPage) return <UnauthorizedView />;

  if (!doesExists) {
    return (
      <Container className="py-5" fluid>
        <Row>
          <Col>
            <h3>EAR Review Not Found</h3>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="py-5" fluid>
      <ERTApproveRejectModal
        show={showCompleteTerminateModal}
        handleClose={handleCloseCompleteTerminateModal}
        review={review}
        refetch={getReview}
        complete={completeView}
        selectedStatus={selectedStatus}
      />
      <EarEscalateErtModal
        show={showEscalateModal}
        handleClose={() => setShowEscalateModal(false)}
        review={review}
        refetch={getReview}
        setAlert={setAlert}
      />
      <EarResponseModal
        show={showResponseModal}
        handleClose={handleCloseResponseModal}
        review={review}
        refetch={getReview}
      />
      <EarResubmitModal
        show={showResubmitModal}
        handleClose={handleCloseResubmitModal}
        review={review}
        refetch={getReview}
        fetchNotes={fetchNotes}
      />
      <Row>
        <Col sm={12} md={8} lg={9}>
          <Row>
            {alert.message !== "" && (
              <Alert
                variant={alert.type}
                className="mt-3"
                dismissible
                onClose={() => setAlert({ message: "", type: "" })}
              >
                {alert.message}
              </Alert>
            )}
            <Col>
              {!readOnly ? (
                permissions?.role_permissions?.find(
                  (permission) => permission.area === "EAR Reviews",
                )?.update && (
                  <Row xs="auto" className="float-end">
                    {userCanEdit && (
                      <Row>
                        <Button
                          variant="danger"
                          className="rounded-pill"
                          onClick={() => showDeleteModal()}
                        >
                          Delete
                        </Button>
                      </Row>
                    )}
                    {currentUser?.user_role?.role_type === "Validator" &&
                      review?.status === "Draft" && (
                        <Col>
                          <Button
                            variant="warning"
                            className="rounded-pill"
                            onClick={() => setShowEscalateModal(true)}
                          >
                            Release to ERT
                          </Button>
                        </Col>
                      )}
                  </Row>
                )
              ) : (
                <Row>
                  <Col></Col>
                  <Col>
                    <h3
                      className={
                        review?.approved
                          ? "fw-bold text-success"
                          : "fw-bold text-danger"
                      }
                    >
                      {review?.approved ? "APPROVED" : "REJECTED"}
                    </h3>
                    {/* {review?.gist !== null && <strong>Reasoning: {review?.gist}</strong>} */}
                  </Col>
                </Row>
              )}
              <Row>
                {(permissions?.role_type === "Validator" ||
                  permissions?.role_type === "NIAP") &&
                  review?.status === "Rejected - Pending Revision" && (
                    <Col className="text-end">
                      <Button
                        variant="primary"
                        className="rounded-pill"
                        onClick={handleOpenResubmitModal}
                      >
                        Resubmit
                      </Button>
                    </Col>
                  )}
              </Row>
            </Col>
            <Col sm={12}></Col>
            <Col sm={6} className="mt-3">
              <h3 className="fw-bold">Details for EAR REVIEW {id}</h3>
            </Col>
            <Col sm={6} className="mt-3 d-flex align-items-center">
              <div className="vr me-3"></div>
              <h3 className="fw-bold">
                VID {review?.pid?.v_id}{" "}
                <Link
                  to={`/community/products/details/${review?.pid?.product_id}`}
                >
                  {review?.pid?.product_name}
                </Link>
              </h3>
            </Col>
            <Col sm={12} className="mt-3">
              <Row>
                <Col
                  sm={12}
                  xl={3}
                  className="border-dark border-bottom border-top"
                >
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "status" ? (
                      <>
                        <h5>Status</h5>
                        <Select
                          id="ear-status-select"
                          options={earStatusOptions}
                          defaultValue={earStatusOptions.find(
                            (s) => s.value === review?.status,
                          )}
                          onChange={(e) =>
                            handleChange({
                              target: { name: "status", value: e.value },
                            })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {canSuperEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("status");
                              }}
                              data-testid="edit-status"
                            />
                          </div>
                        )}
                        <h5>Status</h5>
                        <h5 className="text-secondary">{review?.status}</h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col
                  sm={12}
                  xl={3}
                  className="border-dark border-bottom border-top"
                >
                  <Stack className="border-start border-secondary ps-2 my-2">
                    <h5>PP Claim</h5>
                    {earPPs.map((p, idx) => (
                      <h5 key={idx} className="text-secondary">
                        {p?.pp__pp_name}
                      </h5>
                    ))}
                  </Stack>
                </Col>
                <Col
                  sm={12}
                  xl={2}
                  className="border-dark border-bottom border-top"
                >
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "reqd" ? (
                      <>
                        <h5>EAR Required for Evaluation</h5>
                        <Select
                          options={noYesOptions}
                          defaultValue={noYesOptions.find(
                            (s) => s.value === review?.reqd,
                          )}
                          onChange={(e) =>
                            handleChange({
                              target: { name: "reqd", value: e.value },
                            })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("reqd");
                              }}
                              data-testid="edit-ear-required"
                            />
                          </div>
                        )}
                        <h5>EAR Required for Evaluation</h5>
                        <h5 className="text-secondary">
                          {review?.reqd ? "Yes" : "No"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col
                  sm={12}
                  xl={2}
                  className="border-dark border-bottom border-top"
                >
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "apriori" ? (
                      <>
                        <h5>Previous Approval</h5>
                        <Select
                          options={noYesOptions}
                          defaultValue={noYesOptions.find(
                            (s) => s.value === review?.apriori,
                          )}
                          onChange={(e) =>
                            handleChange({
                              target: { name: "apriori", value: e.value },
                            })
                          }
                        />
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("apriori");
                              }}
                              data-testid="edit-previous-approval"
                            />
                          </div>
                        )}
                        <h5>Previous Approval</h5>
                        <h5 className="text-secondary">
                          {review?.apriori ? "Yes" : "No"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col
                  sm={12}
                  xl={2}
                  className="border-dark border-bottom border-top"
                >
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "due" ? (
                      <>
                        <Form.Group controlId="due">
                          <Form.Label>Deadline Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="due"
                            defaultValue={moment
                              .utc(review?.due)
                              .format("YYYY-MM-DD")}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </>
                    ) : (
                      <>
                        {userCanEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("due");
                              }}
                              data-testid="edit-deadline-date"
                            />
                          </div>
                        )}
                        <h5>Deadline Date</h5>
                        <h5 className="text-secondary">
                          {review?.due
                            ? moment.utc(review?.due).format("MM/DD/YYYY")
                            : "No Date"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
                <Col sm={12} xl={4} className="border-dark border-bottom">
                  <Stack className="border-start border-secondary ps-2 my-2">
                    {isEditing === "agent" ? (
                      <>
                        <div className="d-flex justify-content-end">
                          <EditIcon
                            className="edit-button clickable"
                            onClick={() => {
                              setIsEditing("");
                            }}
                            data-testid="edit-agent"
                          />
                        </div>
                        <h5>Assigned Agents</h5>
                        <Row>
                          <Col>
                            <ReactSelect
                              id={"agents"}
                              name="agents"
                              handleChange={(e) => {
                                setSelectedAgents({
                                  ...selectedAgents,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                              defaultValue={ertMembers.filter((user) =>
                                review?.agents?.some(
                                  (agent) => agent.id === user.value,
                                ),
                              )}
                              options={ertMembers}
                              newObj={selectedAgents}
                              isMulti={true}
                              isValid={true}
                            />
                          </Col>
                          <Col sm={2} className="m-auto">
                            <SaveIcon
                              className="save-button clickable"
                              onClick={handleSaveAgents}
                              data-testid="save-agents"
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {canSuperEdit && (
                          <div className="d-flex justify-content-end">
                            <EditIcon
                              className="edit-button clickable"
                              onClick={() => {
                                setIsEditing("agent");
                              }}
                              data-testid="edit-agent"
                            />
                          </div>
                        )}
                        <h5>Assigned Agents</h5>
                        <h5 className="text-secondary">
                          {review.agents?.length > 0
                            ? review.agents.map((agent) => (
                                <h5 key={agent.id}>
                                  {agent.first_name} {agent.last_name}
                                </h5>
                              ))
                            : "No Assigned Agents"}
                        </h5>
                      </>
                    )}
                  </Stack>
                </Col>
              </Row>
            </Col>
            <Col sm={12} className="mt-3">
              <Accordion className="mb-4 border-0" defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">JUSTIFICATION</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container>
                      <Row>
                        {isEditing === "just" ? (
                          <Col>
                            <HTMLEditor
                              name="just"
                              handleChange={handleChangeTextField}
                              setIsValid={() => {}}
                              isValid={true}
                              defaultValue={review?.just}
                            />
                          </Col>
                        ) : (
                          <Col>{review?.just && parse(review?.just)}</Col>
                        )}
                        <Col sm={1}>
                          {userCanEdit &&
                            (isEditing === "just" ? (
                              <>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    handleChange({
                                      target: {
                                        name: "just",
                                        value: textFieldUpdate.just,
                                      },
                                    })
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="warning"
                                  className="mt-2"
                                  onClick={() => setIsEditing("")}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <div className="d-flex justify-content-end">
                                <EditIcon
                                  className="edit-button clickable"
                                  onClick={() => {
                                    setIsEditing("just");
                                  }}
                                  data-testid="edit-just"
                                />
                              </div>
                            ))}
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="my-4" flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">INITIAL COMMENTS</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Container>
                      <Row>
                        {isEditing === "blurba" ? (
                          <Col>
                            <HTMLEditor
                              name="blurba"
                              handleChange={handleChangeTextField}
                              setIsValid={() => {}}
                              isValid={true}
                              defaultValue={review?.blurba}
                            />
                          </Col>
                        ) : (
                          <Col>{review?.blurba && parse(review?.blurba)}</Col>
                        )}
                        <Col sm={1}>
                          {userCanEdit &&
                            (isEditing === "blurba" ? (
                              <>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    handleChange({
                                      target: {
                                        name: "blurba",
                                        value: textFieldUpdate.blurba,
                                      },
                                    })
                                  }
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="warning"
                                  className="mt-2"
                                  onClick={() => setIsEditing("")}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <div className="d-flex justify-content-end">
                                <EditIcon
                                  className="edit-button clickable"
                                  onClick={() => {
                                    setIsEditing("blurba");
                                  }}
                                  data-testid="edit-blurba"
                                />
                              </div>
                            ))}
                        </Col>
                      </Row>
                    </Container>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion className="my-4" flush defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <h6 className="fw-bold text-dark">
                      {review?.status === "Pending Review" ||
                      review?.status === "Draft"
                        ? "DRAFT"
                        : "OFFICIAL"}{" "}
                      RESPONSE TO LAB
                    </h6>
                  </Accordion.Header>
                  {!readOnly &&
                    permissions?.role_permissions?.find(
                      (permission) => permission.area === "EAR Reviews",
                    )?.update &&
                    ERTMember &&
                    (review?.status === "Pending Review" ||
                      review?.status === "Draft") && (
                      <Button
                        id="ear-response-button"
                        size="sm"
                        variant="secondary"
                        className="rounded-pill"
                        onClick={() => setShowResponseModal(true)}
                      >
                        {review?.response?.upid ? "Update" : "+ Add"}
                        {review?.status === "Pending Review" ||
                        review?.status === "Draft"
                          ? " Draft"
                          : " Official"}{" "}
                        Response to Lab
                      </Button>
                    )}
                  <Accordion.Body>
                    {(review?.response?.gist || review?.response?.note1) && (
                      <Card className="mb-3">
                        <Card.Body>
                          <Card.Title>
                            <h4>{review?.response?.gist}</h4>
                          </Card.Title>
                          <Card.Text style={{ whiteSpace: "pre-wrap" }}>
                            {review?.response?.note1 &&
                              parse(review?.response?.note1)}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              {(ERTMember ||
                permissions.role_type === "NIAP" ||
                permissions.role_type === "Validator") && (
                <EarNotes
                  review={review}
                  fetchNotes={fetchNotes}
                  notes={notes}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={4} lg={3}>
          <EarSideInfo review={review} id={id} ERTMember={ERTMember} />
        </Col>
      </Row>

      <DeleteModal
        show={showDelete}
        handleClose={handleCloseDeleteModal}
        type={`EAR ${review?.earid}`}
        deleteFunction={handleDeleteEAR}
      />
    </Container>
  );
}
