import { Accordion } from "react-bootstrap";

import { TriggerFunction } from "api/common/types/response.types";
import { Ecr, EcrUpdatePayload } from "api/memberPortal/ecr/ecr.types";
import EditableHtml from "components/DetailsComponents/EditableFields/EditableHtml";

interface EcrRationaleProps {
  allowEdit: boolean;
  rationale: string;
  onSave: TriggerFunction<Partial<EcrUpdatePayload>, Ecr>;
}

const EcrRationale = ({ allowEdit, rationale, onSave }: EcrRationaleProps) => {
  return (
    <Accordion className="mt-4" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h6 className="fw-bold text-dark">RATIONALE</h6>
        </Accordion.Header>

        <Accordion.Body>
          <EditableHtml
            title=""
            editText="Edit Rationale"
            saveText="Save Rationale Comment"
            allowEdit={allowEdit}
            value={rationale || ""}
            onSave={(update) => onSave({ rationale: update })}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default EcrRationale;
