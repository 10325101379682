import { AUTH_ROLES } from "api/common/types/auth.types";
import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import { ECR_STATUSES, EcrStatus } from "api/memberPortal/ecr/ecrOptions.types";
import EditableSelect from "components/DetailsComponents/EditableFields/EditableSelect";
import { useAuth } from "hooks/useAuth";

interface EcrStatusProps {
  allowEdit: boolean;
  onSave: TriggerFunction<EcrStatus, Ecr>;
  status: EcrStatus;
}

const EcrStatusFieldSelect = ({
  allowEdit,
  status,
  onSave,
}: EcrStatusProps) => {
  const {
    permissions: { role_type },
  } = useAuth();
  const isValidator = role_type === AUTH_ROLES.VALIDATOR;

  const editOptions =
    optionsLookup[role_type as keyof typeof optionsLookup] ||
    defaultStatusOptions;

  return (
    <EditableSelect
      value={status}
      onSave={onSave}
      allowEdit={allowEdit || isValidator}
      title="Status"
      options={editOptions}
      id="ecr-select"
      placeholder="Select a Status"
    />
  );
};

const validatorStatusOptions = [
  { label: ECR_STATUSES.DRAFT, value: ECR_STATUSES.DRAFT },
  { label: ECR_STATUSES.PROPOSED, value: ECR_STATUSES.PROPOSED },
  { label: ECR_STATUSES.RELEASED, value: ECR_STATUSES.RELEASED },
  { label: ECR_STATUSES.ON_HOLD, value: ECR_STATUSES.ON_HOLD },
  { label: ECR_STATUSES.CONCEALED, value: ECR_STATUSES.CONCEALED },
  { label: ECR_STATUSES.RESPONSE_TO_LAB, value: ECR_STATUSES.RESPONSE_TO_LAB },
  {
    label: ECR_STATUSES.PENDING_FINAL_REVIEW,
    value: ECR_STATUSES.PENDING_FINAL_REVIEW,
  },
  { label: ECR_STATUSES.REVIEWED, value: ECR_STATUSES.REVIEWED },
  { label: ECR_STATUSES.CANCELLED, value: ECR_STATUSES.CANCELLED },
  { label: ECR_STATUSES.RESOLVED, value: ECR_STATUSES.RESOLVED },
];

const niapStatusOptions = [
  { label: ECR_STATUSES.DRAFT, value: ECR_STATUSES.DRAFT },
  { label: ECR_STATUSES.PROPOSED, value: ECR_STATUSES.PROPOSED },
  { label: ECR_STATUSES.RELEASED, value: ECR_STATUSES.RELEASED },
  { label: ECR_STATUSES.ON_HOLD, value: ECR_STATUSES.ON_HOLD },
  { label: ECR_STATUSES.CONCEALED, value: ECR_STATUSES.CONCEALED },
  { label: ECR_STATUSES.RESPONSE_TO_LAB, value: ECR_STATUSES.RESPONSE_TO_LAB },
  {
    label: ECR_STATUSES.RESPONSE_TO_VALIDATOR,
    value: ECR_STATUSES.RESPONSE_TO_VALIDATOR,
  },
  {
    label: ECR_STATUSES.PENDING_FINAL_REVIEW,
    value: ECR_STATUSES.PENDING_FINAL_REVIEW,
  },
  { label: ECR_STATUSES.REVIEWED, value: ECR_STATUSES.REVIEWED },
  { label: ECR_STATUSES.CANCELLED, value: ECR_STATUSES.CANCELLED },
  { label: ECR_STATUSES.RESOLVED, value: ECR_STATUSES.RESOLVED },
];

const defaultStatusOptions = [
  { label: ECR_STATUSES.DRAFT, value: ECR_STATUSES.DRAFT },
];

const optionsLookup = {
  [AUTH_ROLES.VALIDATOR]: validatorStatusOptions,
  [AUTH_ROLES.NIAP]: niapStatusOptions,
};

export default EcrStatusFieldSelect;
