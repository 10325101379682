import parse from "html-react-parser";
import { useState } from "react";
import { Accordion } from "react-bootstrap";

import { TriggerFunction } from "api/common/types/response.types";
import { Ecr, EcrUpdatePayload } from "api/memberPortal/ecr/ecr.types";
import { TechnicalDecision } from "api/memberPortal/technicalDecisions/technicalDecisions.types";

import EcrEditPpsModal from "./EcrEditPps.modal";

interface EcrTdProps {
  technicalDecisions?: TechnicalDecision[];
  allowEdit: boolean;
  ecr?: Ecr;
  onSave: TriggerFunction<Partial<EcrUpdatePayload>, Ecr>;
}

const EcrTds = ({
  technicalDecisions = [],
  allowEdit,
  ecr,
  onSave,
}: EcrTdProps) => {
  const [isEditing, setIsEditing] = useState(false);

  if (technicalDecisions.length === 0 || !ecr) return;

  return (
    <Accordion className="mb-4 border-0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h6 className="fw-bold text-dark">TECHNICAL DECISIONS</h6>
        </Accordion.Header>

        <Accordion.Body>
          {technicalDecisions.map((td, idx) => (
            <div key={`td${td.td_id}`} className={idx === 0 ? "mt-0" : "mt-4"}>
              <h6 className="text-decoration-underline mx-3">
                {td.title} - {td.identifier}
              </h6>
              <div className="mx-3">
                {td.description && parse(td.description)}
              </div>
            </div>
          ))}

          {allowEdit && (
            <>
              {isEditing && (
                <EcrEditPpsModal
                  ecr={ecr}
                  onSave={(update) => {
                    setIsEditing(false);
                    return onSave({ pp_id: update as number });
                  }}
                  onHide={() => setIsEditing(false)}
                  show={isEditing}
                />
              )}

              <div className="text-end">
                <button
                  className="btn btn-link d-inline-block"
                  style={{ minWidth: 10 }}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Edit Technical Decisions
                </button>
              </div>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default EcrTds;
