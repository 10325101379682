import { skipToken } from "@reduxjs/toolkit/query";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { Option } from "api/common/types/common.types";
import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import {
  useCreateEcrTdMutation,
  useDeleteEcrTdMutation,
  useGetAllTdsForEcrQuery,
  useLazyGetEcrTdByEcrIdAndTdIdQuery,
} from "api/memberPortal/ecr/tds/ecrTds.api";
import { ProjectPp } from "api/memberPortal/project/protectionProfiles/protectionProfile.types";
import { useGetAllProjectPpByProductIdQuery } from "api/memberPortal/project/protectionProfiles/protectionProfiles.api";
import { useGetAllTdsByIdQuery } from "api/memberPortal/technicalDecisions/technicalDecisions.api";
import { TechnicalDecision } from "api/memberPortal/technicalDecisions/technicalDecisions.types";
import { TEditableModalProps } from "components/DetailsComponents/EditableFields/EditableModal";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

const emptyArray: unknown[] = [];

interface EcrEditPpsModalProps extends TEditableModalProps {
  ecr: Ecr;
  onSave: TriggerFunction<string | number, Ecr>;
}

const EcrEditPpsModal = ({ ecr, onHide, onSave }: EcrEditPpsModalProps) => {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const [selectedPp, setSelectedPp] = useState<number | string>();
  const [selectedTds, setSelectedTds] = useState<Option[]>([]);

  const { data: pps = emptyArray as ProjectPp[] } =
    useGetAllProjectPpByProductIdQuery(ecr.pid?.product_id ?? skipToken);
  const ppOptions = useMemo(() => {
    if (!pps) return [];

    return pps.map((pp) => ({
      label: pp.pp__pp_short_name ? pp.pp__pp_short_name : pp.pp__pp_name,
      value: pp.pp__pp_id,
    }));
  }, [pps]);

  const { data: allTdsForPp = emptyArray as TechnicalDecision[] } =
    useGetAllTdsByIdQuery(selectedPp ?? skipToken);
  const allTdOptions = useMemo(() => {
    return allTdsForPp.map((td) => ({
      label: td.identifier ?? td.title,
      value: td.td_id,
    }));
  }, [allTdsForPp]);

  const { data: initialTds = emptyArray as TechnicalDecision[] } =
    useGetAllTdsForEcrQuery(id ?? skipToken);
  const initialTdOptions = useMemo(
    () =>
      initialTds.map((td) => ({
        label: td.identifier ?? (td.title || ""),
        value: td.td_id,
      })),
    [initialTds],
  );
  const availableTdOptions = allTdOptions.filter(
    (td) => !selectedTds.some((selectedTd) => selectedTd.value === td.value),
  );

  const [triggerGetEcrTd] = useLazyGetEcrTdByEcrIdAndTdIdQuery();
  const [triggerDeleteEcrTd] = useDeleteEcrTdMutation();
  const [triggerCreateEcrTd] = useCreateEcrTdMutation();

  const handlePpSubmit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (selectedPp) {
      onSave(selectedPp);
    }

    const tdsToDelete = initialTds.filter(
      (del) => !selectedTds.some((sel) => sel.value === del.td_id),
    );

    tdsToDelete.forEach(async (td) => {
      const ecrTds = await triggerGetEcrTd({
        ecrid: ecr.ecrid,
        tdid: td.td_id,
      }).unwrap();
      if (!ecrTds[0]) return;
      triggerDeleteEcrTd(ecrTds[0].xid);
    });

    const tdsToAdd = selectedTds.filter(
      (item) => !tdsToDelete.some((td) => td.td_id === item.value),
    );
    tdsToAdd.forEach((td) => {
      if (!initialTds.some((originalTd) => originalTd.td_id === td.value)) {
        const payload = {
          entrydate: getISOCurrentDate(),
          ecrid: ecr.ecrid,
          td_id: td.value,
          creator: currentUser.id,
        };
        triggerCreateEcrTd(payload);
      }
    });
  };

  const removeTd = (td: Option) => {
    setSelectedTds(
      selectedTds.filter((selectedTd) => selectedTd.value !== td.value),
    );
  };

  const initialPp = ecr.pp_id?.pp_id;
  useEffect(() => {
    if (!initialPp) return;

    setSelectedPp(initialPp);
  }, [initialPp]);

  useEffect(() => {
    if (selectedPp === ecr.pp_id?.pp_id) {
      setSelectedTds(initialTdOptions);
      return;
    }

    setSelectedTds([]);
  }, [allTdsForPp, ecr.pp_id?.pp_id, initialTdOptions, selectedPp]);

  return (
    <Modal show={true} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>+ Editing PP </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handlePpSubmit}>
          <Form.Label htmlFor="protectionProfile">
            Protection Profile
          </Form.Label>

          <Select
            id="protectionProfile"
            placeholder="Select a Protection Profile"
            options={ppOptions}
            onChange={(e) => {
              e && setSelectedPp(e.value);
            }}
            value={ppOptions.find((pp) => pp.value === selectedPp)}
          />

          <Form.Label htmlFor="technicalDecisions" className="mt-3">
            Technical Decisions
          </Form.Label>

          <Select
            id="technicalDecisions"
            placeholder="Select All Technical Decisions"
            options={availableTdOptions}
            onChange={(td) => td && setSelectedTds([...selectedTds, td])}
          />
          {selectedTds.map((td) => (
            <SelectedTd td={td} removeTd={removeTd} key={td.value} />
          ))}

          <Row className="m-2 mt-4 mb-0">
            <Button type="submit">Submit</Button>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default EcrEditPpsModal;

interface SelectedTdProps {
  td: Option;
  removeTd: (_td: Option) => void;
}
const SelectedTd = ({ td, removeTd }: SelectedTdProps) => {
  return (
    <Row className="m-3 border-bottom text-center">
      <Col sm={6}>{td.label}</Col>
      <Col sm={6}>
        <Button
          variant="outline-primary"
          className="attachment-remove"
          onClick={() => removeTd(td)}
        >
          X
        </Button>
      </Col>
    </Row>
  );
};
