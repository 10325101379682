import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Box, IconButton, Typography, type SxProps } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";

interface BaseEditableFieldProps<TResponse> {
  onSave?: () => Promise<{ data: TResponse } | { error: unknown }>;
  onCancel?: () => void;
  allowEdit: boolean;
  forceClose?: boolean;
  title: string;
  value: string | number; // might need a generic? might be ok?
  EditComponent: ReactNode;
  DisplayComponent?: ReactNode;
  editButtonSize?: "small" | "inherit" | "medium" | "large";
  sx?: SxProps;
  displaySx?: SxProps;
  showBorder?: boolean;
}

const BaseEditableField = <TResponse,>({
  onSave,
  onCancel,
  allowEdit,
  forceClose,
  title,
  value,
  EditComponent,
  DisplayComponent,
  sx,
  displaySx,
  editButtonSize,
  showBorder = true,
}: BaseEditableFieldProps<TResponse>) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    onSave && (await onSave());
    setIsEditing(false);
  };

  const HeaderIcon = isEditing ? SaveIcon : EditIcon;
  const iconAction = isEditing ? handleSave : handleEdit;
  const iconTestId = `${isEditing ? "save" : "edit"}-${title.toLowerCase()}-btn`;

  const handleCancel = () => {
    onCancel && onCancel();
    setIsEditing(false);
  };

  useEffect(() => {
    forceClose && setIsEditing(false);
  }, [forceClose]);

  useEffect(() => {
    setIsEditing(false);
  }, [value]);

  return (
    <Box
      sx={{
        margin: "4px",
        borderLeft: showBorder ? "1px solid #6c757d" : "none",
        borderRadius: "2px",
        flex: {
          lg: "1 0 25%",
          md: "1 0 33%",
          sm: "1 0 50%",
          xs: "1 0 100%",
        },
        maxWidth: {
          lg: "calc(25% - 10px)",
          md: "calc(33% - 10px)",
          sm: "calc(50% - 10px)",
          xs: "100%",
        },
        ...sx,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft=".5em"
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          fontSize="16px"
          color="textPrimary"
          fontStyle="italic"
        >
          {title}
        </Typography>

        <Box>
          {allowEdit && (
            <IconButton onClick={iconAction}>
              <HeaderIcon
                color="action"
                className="clickable"
                data-testid={iconTestId}
                fontSize={editButtonSize || "small"}
                sx={{ justifySelf: "flex-end" }}
              />
            </IconButton>
          )}
          {isEditing && (
            <IconButton onClick={handleCancel}>
              <CloseIcon
                color="action"
                className="clickable"
                data-testid={iconTestId}
                fontSize="small"
              />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box padding=".5em" paddingTop={0}>
        {isEditing && EditComponent}
        {!isEditing &&
          (DisplayComponent || (
            <Typography
              color="textSecondary"
              variant="h5"
              fontSize="18px"
              margin={1}
              sx={displaySx}
            >
              {value}
            </Typography>
          ))}
      </Box>
    </Box>
  );
};

export default BaseEditableField;
