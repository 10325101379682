import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import { ECR_TYPES, EcrType } from "api/memberPortal/ecr/ecrOptions.types";
import EditableSelect from "components/DetailsComponents/EditableFields/EditableSelect";

interface EcrStatusProps {
  allowEdit: boolean;
  onSave: TriggerFunction<EcrType, Ecr>;
  type: EcrType;
}

const ecrTypeOptions = [
  { label: ECR_TYPES.ADMINISTRATIVE, value: ECR_TYPES.ADMINISTRATIVE },
  { label: ECR_TYPES.INFORMATIVE, value: ECR_TYPES.INFORMATIVE },
  { label: ECR_TYPES.SUBSTANTIVE, value: ECR_TYPES.SUBSTANTIVE },
  { label: ECR_TYPES.CRITICAL, value: ECR_TYPES.CRITICAL },
];

const EcrTypeFieldSelect = ({ allowEdit, type, onSave }: EcrStatusProps) => {
  return (
    <EditableSelect
      title="Type"
      value={type}
      onSave={onSave}
      allowEdit={allowEdit}
      options={ecrTypeOptions}
      id="docType"
      placeholder="Select All Document Types"
    />
  );
};

export default EcrTypeFieldSelect;
