import { Accordion } from "react-bootstrap";

import { EcrTq } from "api/memberPortal/ecr/tds/ecrTds.types";

interface EcrTqsProps {
  technicalQueries?: EcrTq[];
}

const EcrTqs = ({ technicalQueries = [] }: EcrTqsProps) => {
  if (technicalQueries.length === 0) return;

  return (
    <Accordion className="my-4" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h6 className="fw-bold text-dark">TECHNICAL QUERIES</h6>
        </Accordion.Header>

        <Accordion.Body>
          {technicalQueries.map((tq) => (
            <div key={`tq${tq.tq_id}`}>
              <h6 className="fw-bold">{tq.title}</h6>
              <p>{tq.question}</p>
              <p>{tq.response}</p>
            </div>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
export default EcrTqs;
