import { useEffect, useMemo, useState } from "react";
import { ListGroup } from "react-bootstrap";
import Select from "react-select";

import { Option } from "api/common/types/common.types";
import {
  useCreateEcrDocMutation,
  useDeleteEcrDocMutation,
  useGetAllEcrDocTypesQuery,
} from "api/memberPortal/ecr/docs/ecrDocs.api";
import { EcrDocStub } from "api/memberPortal/ecr/docs/ecrDocs.types";
import {
  ECR_FILE_TYPES,
  EcrFileType,
} from "api/memberPortal/ecr/ecrOptions.types";
import BaseEditableField from "components/DetailsComponents/EditableFields/BaseEditableField";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

interface FileType {
  label: EcrFileType;
  value: EcrFileType;
}
const allEcrDocOptions: FileType[] = [
  {
    label: ECR_FILE_TYPES.ADMINISTRATIVE_GUIDE_AGD,
    value: ECR_FILE_TYPES.ADMINISTRATIVE_GUIDE_AGD,
  },
  {
    label: ECR_FILE_TYPES.ASSURANCE_VULNERABILITY_ASSESSMENT_AVA,
    value: ECR_FILE_TYPES.ASSURANCE_VULNERABILITY_ASSESSMENT_AVA,
  },
  {
    label: ECR_FILE_TYPES.ENTROPY_ASSESSMENT_REPORT_EAR,
    value: ECR_FILE_TYPES.ENTROPY_ASSESSMENT_REPORT_EAR,
  },
  {
    label: ECR_FILE_TYPES.KEY_MANAGEMENT_DESCRIPTION,
    value: ECR_FILE_TYPES.KEY_MANAGEMENT_DESCRIPTION,
  },
  {
    label: ECR_FILE_TYPES.VALIDATION_REPORT,
    value: ECR_FILE_TYPES.VALIDATION_REPORT,
  },
  {
    label: ECR_FILE_TYPES.ASSURANCE_ACTIVITY_REPORT_AAR,
    value: ECR_FILE_TYPES.ASSURANCE_ACTIVITY_REPORT_AAR,
  },
  {
    label: ECR_FILE_TYPES.CHECK_IN_PACKAGE,
    value: ECR_FILE_TYPES.CHECK_IN_PACKAGE,
  },
  {
    label: ECR_FILE_TYPES.EVALUATION_TECHNICAL_REPORT_ETR,
    value: ECR_FILE_TYPES.EVALUATION_TECHNICAL_REPORT_ETR,
  },
  {
    label: ECR_FILE_TYPES.SECURITY_TARGET,
    value: ECR_FILE_TYPES.SECURITY_TARGET,
  },
  {
    label: ECR_FILE_TYPES.OTHER,
    value: ECR_FILE_TYPES.OTHER,
  },
  {
    label: ECR_FILE_TYPES.ASSURANCE_CONTINUITY_MAINTENANCE_REPORT_ACMR,
    value: ECR_FILE_TYPES.ASSURANCE_CONTINUITY_MAINTENANCE_REPORT_ACMR,
  },
  {
    label: ECR_FILE_TYPES.CHECK_OUT_PACKAGE,
    value: ECR_FILE_TYPES.CHECK_OUT_PACKAGE,
  },
  {
    label: ECR_FILE_TYPES.IMPACT_ANALYSIS_REPORT_IAR,
    value: ECR_FILE_TYPES.IMPACT_ANALYSIS_REPORT_IAR,
  },
  {
    label: ECR_FILE_TYPES.TEST_REPORTS,
    value: ECR_FILE_TYPES.TEST_REPORTS,
  },
].sort((a, b) => (a.label > b.label ? 1 : -1));

const emptyArray: EcrDocStub[] = [];

interface EcrDocumentsProps {
  userCanEdit: boolean;
  ecrid: number;
}
const EcrDocumentsField = ({ userCanEdit, ecrid }: EcrDocumentsProps) => {
  const [selectedDocTypes, setSelectedDocTypes] = useState<Option[]>([]);
  const { currentUser } = useAuth();

  const { data: originalDocTypes = emptyArray } = useGetAllEcrDocTypesQuery();
  const [triggerCreateEcrDoc] = useCreateEcrDocMutation();
  const [triggerDeleteEcrDoc] = useDeleteEcrDocMutation();

  const originalDocTypesForEcr = useMemo(
    () => originalDocTypes.filter((dt) => dt.ecrid__ecrid === ecrid),
    [originalDocTypes, ecrid],
  );

  const originalDocTypeOptions = useMemo(
    () =>
      originalDocTypesForEcr
        .map((docType) => ({
          label: docType.dtid__doctype,
          value: docType.dtid__doctype,
        }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    [originalDocTypesForEcr],
  );

  useEffect(() => {
    setSelectedDocTypes(originalDocTypeOptions);
  }, [originalDocTypeOptions]);

  const handleSave = async () => {
    const docsToCreate = selectedDocTypes.filter(
      (sel) => !originalDocTypeOptions.some((org) => org.value === sel.value),
    );
    const createPromises = docsToCreate.map((doc) =>
      triggerCreateEcrDoc({
        entrydate: getISOCurrentDate(),
        ecrid: ecrid,
        doc_type: doc.value as EcrFileType,
        creator: currentUser.id,
      }),
    );

    const docsToDelete = originalDocTypesForEcr.filter((org) =>
      selectedDocTypes.every((sel) => sel.label !== org.dtid__doctype),
    );
    const deletePromises = docsToDelete.map((doc) =>
      triggerDeleteEcrDoc(doc.xid),
    );

    try {
      const results = await Promise.all([...createPromises, ...deletePromises]);
      return { data: results };
    } catch (error) {
      return { error };
    }
  };

  return (
    <BaseEditableField
      title="Documents"
      allowEdit={userCanEdit}
      onSave={handleSave}
      onCancel={() => setSelectedDocTypes(originalDocTypeOptions)}
      value={"Documents"}
      DisplayComponent={
        <ListGroup>
          {originalDocTypeOptions.map((dt) => (
            <ListGroup.Item key={`dt-${dt.value}`} disabled>
              {dt.label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      }
      EditComponent={
        <>
          <Select
            id="docType"
            isMulti
            required
            placeholder="Select All Document Types"
            options={allEcrDocOptions}
            value={selectedDocTypes}
            onChange={(e) => e && setSelectedDocTypes([...e])}
          />
        </>
      }
    />
  );
};
export default EcrDocumentsField;
