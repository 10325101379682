import { baseApi } from "api/BaseApi";

import {
  EcrNote,
  EcrNotePayload,
  ReleaseResponsesPayload,
} from "./ecrNotes.types";

const ECR_NOTES_BASE_URL = "ecr/update";

const ecrNotesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    ecrNotes: build.query<EcrNote[], number | string>({
      query: (ecrid) => ({
        url: `${ECR_NOTES_BASE_URL}/updates_by_ecr/`,
        params: { ecrid },
      }),
      providesTags: ["ECR_NOTES"],
    }),

    ecrNoteCreate: build.mutation<EcrNote, EcrNotePayload>({
      query: (body) => ({
        url: `${ECR_NOTES_BASE_URL}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ECR_NOTES"],
    }),

    ecrNoteUpdate: build.mutation<EcrNote, EcrNotePayload>({
      query: ({ upid, ...body }) => ({
        url: `${ECR_NOTES_BASE_URL}/${upid}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { upid }) => [
        "ECR_NOTES",
        { type: "ECR_NOTES", id: upid },
      ],
    }),

    ecrNoteDelete: build.mutation<void, number | string>({
      query: (upid) => ({
        url: `${ECR_NOTES_BASE_URL}/${upid}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ECR_NOTES"],
    }),

    releaseEcrResponse: build.mutation<EcrNote[], ReleaseResponsesPayload>({
      query: (body) => ({
        url: `${ECR_NOTES_BASE_URL}/release_responses/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ECR_NOTES"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useEcrNotesQuery,
  useEcrNoteDeleteMutation,
  useReleaseEcrResponseMutation,
  useEcrNoteCreateMutation,
  useEcrNoteUpdateMutation,
} = ecrNotesApi;
