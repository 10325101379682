import { UserStub } from "api/common/types/auth.types";
import { TDateISO } from "api/common/types/datetime.types";
import { valueof } from "api/common/utils/type.utils";

export const EAR_STATUSES = {
  PENDING_REVIEW: "Pending Review",
  DRAFT: "Draft",
  RELEASED: "Released",
  COMPLETED_APPROVED_WITH_PROVISIONS: "Completed - Approved with Provisions",
  COMPLETED_APPROVED_TO_PROCEED: "Completed - Approved to Proceed",
  COMPLETED_EAR_PREVIOUSLY_APPROVED: "Completed - EAR Previously Approved",
  COMPLETED_NO_EAR_REQUIRED: "Completed - No EAR Required",
  REJECTED_PENDING_REVISION: "Rejected - Pending Revision",
  DELETED: "Deleted",
} as const;
export type EarStatus = valueof<typeof EAR_STATUSES>;

export interface EarFileData {
  file_type: "ear-review";
  file_source_type: "Check In";
  file_display_name: "Entropy Assessment Report (EAR)";
  uploaded_on: string;
  file_name: string;
  file_label: string;
  active: true;
  file: File | undefined;
  private: true;
}

export interface EarFormFields {
  apriori: boolean;
  reqd: boolean;
  blurba: string;
  just: string;
  ear_file_label: string;
  tier3: boolean;
}

export interface EarCreationPayload {
  earFile: EarFileData;
  productId: string;
  currentUser: { id: number };
  ear: EarFormFields;
}

export interface Ear {
  active: boolean;
  agent: UserStub | null;
  approved: boolean;
  apriori: boolean;
  blurba: string | null;
  creator: number;
  due: TDateISO | null;
  earid: number;
  editor: number;
  editorext: number | null;
  enddate: TDateISO | null;
  entrydate: TDateISO;
  escalated: boolean;
  escalation_comment: string | null;
  escalator: UserStub | null;
  fid: any | null; // TODO: Update with File type once merged in
  gist: string | null;
  just: string;
  moddate: TDateISO | null;
  modext: TDateISO | null;
  pid: number;
  rcvd: TDateISO | null;
  rejected: boolean;
  release_date: TDateISO | null;
  reqd: boolean;
  response: any | null; // not sure - need to figure out an Earupdate type
  status: EarStatus;
  ticket: string | null;
  tier3: boolean;
}
