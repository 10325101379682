import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import {
  ECR_PROCESS_LOCATIONS,
  EcrProcessLocation,
} from "api/memberPortal/ecr/ecrOptions.types";
import EditableSelect from "components/DetailsComponents/EditableFields/EditableSelect";

const processLocationOptions = [
  {
    label: ECR_PROCESS_LOCATIONS.CHECK_IN,
    value: ECR_PROCESS_LOCATIONS.CHECK_IN,
  },
  {
    label: ECR_PROCESS_LOCATIONS.CHECK_OUT,
    value: ECR_PROCESS_LOCATIONS.CHECK_OUT,
  },
  {
    label: ECR_PROCESS_LOCATIONS.ASSURANCE_MAINTENANCE,
    value: ECR_PROCESS_LOCATIONS.ASSURANCE_MAINTENANCE,
  },
];

interface EcrRelatesToProps {
  allowEdit: boolean;
  onSave: TriggerFunction<EcrProcessLocation, Ecr>;
  processLocation: EcrProcessLocation;
}

const EcrRelatesToFieldSelect = ({
  allowEdit,
  processLocation,
  onSave,
}: EcrRelatesToProps) => {
  return (
    <EditableSelect
      title="Relates To"
      value={processLocation}
      onSave={onSave}
      allowEdit={allowEdit}
      options={processLocationOptions}
      id="docType"
      placeholder="Select a Process Location"
    />
  );
};
export default EcrRelatesToFieldSelect;
