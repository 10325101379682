import { skipToken } from "@reduxjs/toolkit/query";
import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useEcrsForVidQuery } from "api/memberPortal/ecr/ecr.api";
import { Ecr } from "api/memberPortal/ecr/ecr.types";

interface EcrBreadcrumbsProps {
  ecr?: Ecr;
  id?: string;
}

const EcrBreadcrumbs = ({ ecr, id }: EcrBreadcrumbsProps) => {
  const navigate = useNavigate();

  const vid = ecr?.pid?.v_id;
  const { data: relatedEcrs = [] } = useEcrsForVidQuery(vid || skipToken);

  if (!ecr) return null;

  const currentEcrIndex = relatedEcrs.findIndex((e) => e.ecrid === ecr.ecrid);
  const hasRelatedEcrs = relatedEcrs.length > 0;
  const lastIndex = relatedEcrs.length - 1;
  const lastEcr = currentEcrIndex === lastIndex;
  const firstEcr = currentEcrIndex === 0;
  const nextIndex = lastEcr ? 0 : currentEcrIndex + 1;
  const prevIndex = firstEcr ? lastIndex : currentEcrIndex - 1;

  const productId = ecr.pid?.product_id;
  const maintenanceId = ecr.maintenance_id?.maintenance_id;
  const productMaintenanceId = ecr.maintenance_id?.product?.product_id;

  let breadcrumbLink = "";
  let breadcrumbText = "";
  let allowLink = true;

  if (productId) {
    if (!ecr.pid) allowLink = false;

    breadcrumbLink = `/community/products/details/${productId}`;
    breadcrumbText = `VID ${vid} Details`;
  } else if (maintenanceId) {
    if (!ecr.maintenance_id?.maintenance_id) allowLink = false;

    breadcrumbLink = `/community/products/details/${productMaintenanceId}/assurancemaintenance/${maintenanceId}`;
    breadcrumbText = `Assurance Maintenance ${maintenanceId} Details`;
  }

  const showBreadcrumb = Boolean(allowLink && (productId || maintenanceId));

  return (
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => navigate(`/`)}>Home</Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => navigate(`/community/`)}>
        Community
      </Breadcrumb.Item>
      <Breadcrumb.Item onClick={() => navigate(`/community/ecr-comments/`)}>
        ECR Comments
      </Breadcrumb.Item>

      {showBreadcrumb && (
        <Breadcrumb.Item onClick={() => navigate(breadcrumbLink)}>
          {breadcrumbText}
        </Breadcrumb.Item>
      )}

      {hasRelatedEcrs && !firstEcr && (
        <Breadcrumb.Item
          onClick={() =>
            navigate(
              `/community/ecr-comments/details/${relatedEcrs[prevIndex].ecrid}`,
            )
          }
        >
          Previous ECR
        </Breadcrumb.Item>
      )}

      <Breadcrumb.Item active>ECR COMMENT : {id}</Breadcrumb.Item>
      {hasRelatedEcrs && !lastEcr && (
        <Breadcrumb.Item
          onClick={() =>
            navigate(
              `/community/ecr-comments/details/${relatedEcrs[nextIndex].ecrid}`,
            )
          }
        >
          Next ECR
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};
export default EcrBreadcrumbs;
