import { baseApi } from "api/BaseApi";
import { PaginatedResponse } from "api/common/types/response.types";
import { TableParams } from "api/common/types/tables.types";

import { Ecr, EcrPayload, EcrUpdatePayload } from "./ecr.types";

const ECR_BASE_URL = "ecr";

const ecrApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    ecr: build.query<Ecr, number | string>({
      query: (id) => `${ECR_BASE_URL}/${id}/`,
      providesTags: (_result, _error, id) => [{ type: "ECR", id }],
    }),

    ecrs: build.query<PaginatedResponse<Ecr>, TableParams>({
      query: (params) => ({ url: ECR_BASE_URL, params }),
    }),

    ecrCreate: build.mutation<Ecr, EcrPayload>({
      query: (body) => ({
        url: `${ECR_BASE_URL}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ECR_COMMENTS"],
    }),

    ecrUpdate: build.mutation<Ecr, Partial<EcrUpdatePayload>>({
      query: ({ ecrid, ...body }) => ({
        url: `${ECR_BASE_URL}/${ecrid}/`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_result, _error, { ecrid }) => [
        "ECR_COMMENTS",
        { type: "ECR", id: ecrid },
      ],
    }),

    ecrDelete: build.mutation<Ecr, number | string>({
      query: (ecrId) => ({
        url: `${ECR_BASE_URL}/${ecrId}/`,
        method: "PUT",
        body: { active: false },
      }),
      invalidatesTags: ["ECR_COMMENTS"],
    }),

    ecrsForVid: build.query<Ecr[], number | string>({
      query: (vid) => ({
        url: `${ECR_BASE_URL}/comments_by_vid`,
        params: { vid },
      }),
      transformResponse: (response: Ecr[]) =>
        response.sort((a, b) => (a.ecrid < b.ecrid ? -1 : 1)),
    }),

    // TODO: looks like i forgot types on my first pass here
    maintenanceEcr: build.query({
      query: (ecrid) => ({
        url: `${ECR_BASE_URL}/comments_by_maintenance_id`,
        params: { ecrid },
      }),
    }),

    ecrCounts: build.query({
      query: (vid) => ({
        url: `${ECR_BASE_URL}/count_comments`,
        params: { vid },
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useEcrQuery,
  useEcrsQuery,
  useEcrCreateMutation,
  useEcrUpdateMutation,
  useEcrDeleteMutation,
  useEcrsForVidQuery,
  useMaintenanceEcrQuery,
  useEcrCountsQuery,
} = ecrApi;
