import { baseApi } from "api/BaseApi";

import { EcrDocResponse, EcrDocPayload } from "../ecr.types";

import { EcrDoc, EcrDocStub } from "./ecrDocs.types";

const ECR_DOC_URL = "ecr/doc";

const ecrDocApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createEcrDoc: build.mutation<EcrDocResponse, EcrDocPayload>({
      query: (body) => ({
        url: `${ECR_DOC_URL}/`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ECR_DOCS"],
    }),

    // TODO: reevaluate the response type
    deleteEcrDoc: build.mutation<EcrDocResponse, number>({
      query: (id) => ({
        url: `${ECR_DOC_URL}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ECR_DOCS"],
    }),

    getAllDocTypesForEcr: build.query<EcrDoc[], number>({
      query: (ecrid) => ({
        url: `${ECR_DOC_URL}/all_doc_types_for_ecr/`,
        params: { ecrid },
      }),
    }),

    // NOTE: this endpoint returns ~33k objects
    // We should strongly look into altering how this works
    // It is only used in the ECR Comments Table and the ECR Details page rn
    // the RTKQ caching will help but this is still a huge endpoint that we are
    // filtering for a single records use
    getAllEcrDocTypes: build.query<EcrDocStub[], void>({
      query: () => `${ECR_DOC_URL}/get_all_ecr_doc_types/`,
      providesTags: ["ECR_DOCS"],
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetAllDocTypesForEcrQuery,
  useGetAllEcrDocTypesQuery,
  useDeleteEcrDocMutation,
  useCreateEcrDocMutation,
} = ecrDocApi;
