import { Modal, Button } from "react-bootstrap";

export default function DeleteModal({
  show,
  handleClose,
  type,
  deleteFunction,
  isRetract,
  isTerminate,
  isReject,
  isDisapproved,
  isArchive,
  isPermanentDelete,
}) {
  const getTypeOfRemove = () => {
    if (isRetract) {
      return "Retract";
    } else if (isTerminate) {
      return "Terminate";
    } else if (isReject) {
      return "Reject";
    } else if (isDisapproved) {
      return "Disapprove";
    } else if (isArchive) {
      return "Archive";
    } else if (isPermanentDelete) {
      return "Permanently Delete";
    } else {
      return "Delete";
    }
  };

  return (
    <Modal size="md" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>
          {getTypeOfRemove()} {type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">
          Are You Sure You Want to {getTypeOfRemove()} This {type}?
        </h4>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" className="m-1" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" className="m-1" onClick={deleteFunction}>
            {getTypeOfRemove()}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
