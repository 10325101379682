import axios from "axios";
import { useState } from "react";
import { Modal, Container, Row, Col, Form, Button } from "react-bootstrap";

import { useAuth } from "hooks/useAuth";

export default function TQFileUploadModal({
  show,
  handleClose,
  technicalQuery,
  files,
  handleRemoveFile,
  refetch,
}) {
  const [file, setFile] = useState({});
  const { authToken, csrfToken } = useAuth();

  const file_types = {
    other: "Other",
  };

  const handleChange = (e) => {
    if (e.target.name === "file") {
      setFile({ ...file, [e.target.name]: e.target.files[0] });
    } else {
      setFile({ ...file, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submittedData = new FormData();
    submittedData.append("file_type", "technical_query");
    submittedData.append("file_type_id", technicalQuery?.tq_id);
    submittedData.append(`file`, file?.file);
    submittedData.append("file_display_name", file_types[file?.file_type]);
    submittedData.append("file_name", file?.file?.name);
    submittedData.append("file_label", file?.file_label);
    submittedData.append("file_location", "uploads");
    submittedData.append("file_mime_type", file?.file?.type);
    submittedData.append(
      "uploaded_on",
      new Date(
        new Date().setMinutes(
          new Date().getMinutes() - new Date().getTimezoneOffset(),
        ),
      ).toISOString(),
    );
    submittedData.append("file_source_type", "Other");
    submittedData.append("active", JSON.stringify(true));
    if (file.file_public_status === "Proprietary") {
      submittedData.append("private", JSON.stringify(true));
    } else {
      submittedData.append("private", JSON.stringify(false));
    }
    axios
      .post(`${process.env.REACT_APP_DJANGO_ENDPOINT}file/`, submittedData, {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          Authorization: `Token ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        refetch();
      })
      .catch(() => {
        console.log("Error");
      });
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title as="h3" className="text-primary">
          UPLOAD FILES
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Container fluid>
            <Row className="pt-2">
              <Col xl={2} sm={12}>
                <Form.Group className="text-start" controlId="file_type">
                  <Form.Label className="small text-secondary">Type</Form.Label>
                  <Form.Select
                    name="file_type"
                    value={file?.file_type ?? ""}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="other">Other</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_public_status"
                >
                  <Form.Label className="small text-secondary">
                    Document Visibility
                  </Form.Label>
                  <Form.Select
                    name="file_public_status"
                    value={file?.file_public_status ?? ""}
                    onChange={handleChange}
                  >
                    <option value=""></option>
                    <option value="Public">Public</option>
                    <option value="Proprietary">Proprietary</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xl={3} sm={12}>
                <Form.Group
                  className="text-start"
                  controlId="file_public_status"
                >
                  <Form.Label className="small text-secondary">
                    File Label
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="file_label"
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col xl={4} sm={12}>
                <Form.Group controlId="file">
                  <Form.Label className="small text-secondary">
                    Attach Files
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="file"
                    onChange={handleChange}
                    disabled={
                      !file.file_type ||
                      !file.file_public_status ||
                      !file.file_label
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end mb-3 mt-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-2"
                  disabled={
                    !file.file_type ||
                    !file.file_public_status ||
                    !file.file_label ||
                    !file.file
                  }
                >
                  Upload File
                </Button>
              </Col>
            </Row>
            {files && (
              <div className="border">
                <p className="m-2">Attached Files</p>
                {files.map((file) => (
                  <Row
                    className="m-3 d-flex justify-content-around"
                    key={file.file_id}
                  >
                    <Col sm={4}>{file.file_display_name}</Col>
                    <Col sm={4}>{file.file_label}</Col>
                    <Col sm={2}>{file.private ? "Proprietary" : "Public"}</Col>
                    <Col sm={1} className="d-flex justify-content-center">
                      <Button
                        variant="outline-primary"
                        className="attachment-remove"
                        onClick={() => handleRemoveFile(file)}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
          </Container>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
