import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu } from "@mui/material";
import { useCallback, useState } from "react";

import { useAuth } from "hooks/useAuth";

import PfaApprovalAction from "./actions/PfaApproval.action";
import PfaDeleteAction from "./actions/PfaDelete.action";
import PfaPublishAction from "./actions/PfaPublish.action";
import PfaRelabelAction from "./actions/PfaRelabel.action";
import PfaRenameAction from "./actions/PfaRename.action";
import PfaReplaceAction from "./actions/PfaReplace.action";

const ACTIONS = {
  REPLACE: "replace",
  APPROVE: "approval",
  PUBLISH: "publish",
  RELABEL: "relabel",
  RENAME: "rename",
  DELETE: "delete",
};

const PERMISSIONS = {
  ALL: "all",
  OWN: "own",
  NONE: "none",
};

// TODO: Unify with new permission types
const PERMISSION_GROUPS = {
  Lab: {
    replace: PERMISSIONS.NONE,
    approve: PERMISSIONS.NONE,
    publish: PERMISSIONS.NONE,
    relabel: PERMISSIONS.OWN,
    rename: PERMISSIONS.OWN,
    delete: PERMISSIONS.NONE,
  },

  Validator: {
    replace: PERMISSIONS.OWN,
    approve: PERMISSIONS.NONE,
    publish: PERMISSIONS.NONE,
    relabel: PERMISSIONS.OWN,
    rename: PERMISSIONS.OWN,
    delete: PERMISSIONS.NONE,
  },
};

const INACTIVE_STATUSES = ["Archived", "Certified", "Terminated", "Withdrawn"];

// TODO: We are now using this pattern in 3 locations,
// At this point we should definitely refactor this into a reusable component
const PfaActionsCell = ({ file, product }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { currentUser } = useAuth();

  const userRole = currentUser.user_role.role_type;
  const userId = currentUser.id;

  const checkPermissions = useCallback(
    (action) => {
      if (userRole === "NIAP") return true;
      const userPermissions = PERMISSION_GROUPS[userRole];

      if (userPermissions === PERMISSIONS.NONE) {
        return false;
      }

      if (userPermissions[action] === PERMISSIONS.ALL) {
        return true;
      }

      if (userPermissions[action] === PERMISSIONS.OWN) {
        return file.uploaded_by.id === userId;
      }
    },
    [userRole, userId, file],
  );

  // If you are not NIAP you can only edit your own files
  if (userRole !== "NIAP" && file.uploaded_by.id !== userId) return null;

  // Once a product is inactive only NIAP can edit files
  if (userRole !== "NIAP" && INACTIVE_STATUSES.includes(product.status))
    return null;

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canSeeReplace = checkPermissions(ACTIONS.REPLACE);
  const canSeeApprove = checkPermissions(ACTIONS.APPROVE);
  const canSeePublish = checkPermissions(ACTIONS.PUBLISH);
  const canSeeRelabel = checkPermissions(ACTIONS.RELABEL);
  const canSeeRename = checkPermissions(ACTIONS.RENAME);
  const canSeeDelete = checkPermissions(ACTIONS.DELETE);

  return (
    <>
      <IconButton
        id={`actions-menu-button-${file.file_id}`}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon color="primary" />
      </IconButton>
      <Menu
        id={`actions-menu-${file.file_id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": `actions-menu-button-${file.file_id}`,
        }}
      >
        {canSeeReplace && <PfaReplaceAction file={file} />}
        {canSeeApprove && <PfaApprovalAction file={file} />}
        {canSeePublish && <PfaPublishAction file={file} />}
        {canSeeRelabel && <PfaRelabelAction file={file} />}
        {canSeeRename && <PfaRenameAction file={file} />}
        {canSeeDelete && <PfaDeleteAction file={file} />}
      </Menu>
    </>
  );
};

export default PfaActionsCell;
