import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import EditableModal from "components/DetailsComponents/EditableFields/EditableModal";

import EcrEditPpsModal from "../EcrEditPps.modal";

interface EcrPpsProps {
  ecr: Ecr;
  onSave: TriggerFunction<number, Ecr>;
  allowEdit: boolean;
}

const EcrPpsField = ({ ecr, onSave, allowEdit }: EcrPpsProps) => {
  return (
    <EditableModal
      allowEdit={allowEdit}
      title="PP Claim "
      value={ecr.pp_id?.pp_name}
      onSave={onSave}
      Modal={EcrEditPpsModal}
      id="ecr-pps-modal"
      modalProps={{ ecr }}
    />
  );
};
export default EcrPpsField;
