import { baseApi } from "api/BaseApi";

import {
  AllArchivedOrRelatedTdsResponse,
  TechnicalDecision,
} from "./technicalDecisions.types";

const TECHNICAL_DECISIONS_BASE_URL = "technical-decision";

const technicalDecisionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTdsById: build.query<TechnicalDecision[], number | string>({
      query: (id) => ({
        url: `${TECHNICAL_DECISIONS_BASE_URL}/get_all_tds_by_id/`,
        params: {
          profile: id,
        },
      }),
    }),
    getAllArchivedOrRelatedTdsById: build.query<
      AllArchivedOrRelatedTdsResponse,
      string
    >({
      query: (id) => ({
        url: `${TECHNICAL_DECISIONS_BASE_URL}/get_all_archived_or_related_tds_by_id/`,
        params: {
          profile: id,
        },
      }),
    }),
  }),

  overrideExisting: false,
});

export const { useGetAllTdsByIdQuery, useGetAllArchivedOrRelatedTdsByIdQuery } =
  technicalDecisionsApi;
