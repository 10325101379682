import axios from "axios";
import qs from "qs";
import { useRef, useState, createContext, useMemo } from "react";
import { Row, Container, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";

import { useRemoveFileMutation } from "api/memberPortal/files/files.api";
import RelabelObject from "components/DocumentManager/RelabelObject";
import RenameObject from "components/DocumentManager/RenameObject";
import FileUploadModal from "components/Products/FileUpload";
import DeleteModal from "components/UI/DeleteModal";
import ResponsiveMaterialTable from "components/UI/MaterialTable/ResponsiveMaterialTable";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { useInterval } from "hooks/useInterval";

import ReplaceFileModal from "./ReplaceFileModal";
import { buildPfaColumns } from "./table/Pfa.columns";

export const PfaContext = createContext();

const FileTableCheckPermissions = () => {
  const { type } = useParams();
  const { permissions } = useAuth();

  if (type === "protection-profile" && permissions.role_type !== "NIAP") {
    return <UnauthorizedView />;
  }

  return <FileTable />;
};

const TYPE_LOOKUP = {
  product_files: "product",
  am_files: "assurance-maintenance",
  pcm_files: "ccp",
};

const FileTable = () => {
  const { authToken } = useAuth();
  const { id, type, ccid } = useParams();
  const tableRef = useRef();

  const [triggerRemoveFile] = useRemoveFileMutation();

  // Modal Controls
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showReplaceFileModal, setShowReplaceFileModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showRelabelModal, setShowRelabelModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [product, setProduct] = useState({});
  const [activeFile, setActiveFile] = useState({});

  const columns = useMemo(() => buildPfaColumns({ product }), [product]);

  const reloadDocuments = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const updateFiles = () => {
    setShowFileUploadModal(false);
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };

  const handleSoftDeleteFile = () => {
    triggerRemoveFile(activeFile.file_id);
    setShowDeleteModal(false);
    reloadDocuments();
  };

  const endpoint =
    type === "pcm_files"
      ? "get_pcm_files_by_ccid/"
      : "get_files_by_type_and_type_id/";

  // Update type based on url passed to the component
  const updatedType = TYPE_LOOKUP[type] || type;

  if (updatedType === "product" && !product.v_id) {
    axios
      .get(`${process.env.REACT_APP_DJANGO_ENDPOINT}project/product/${id}/`, {
        withCredentials: true,
        headers: {
          Authorization: `Token ${authToken}`,
        },
      })
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {});
  }

  useInterval(() => {
    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, 300000); //5 min

  const options = {
    headerStyle: {
      fontWeight: "bold",
      borderBottom: "none",
      paddingBottom: "0",
    },
    filtering: false,
    columnsButton: true,
    pageSize: 20,
    emptyRowsWhenPaging: false,
  };

  const capitalizeHyphens = (text) => {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join("-");
  };

  const modals = {
    replace: {
      open: () => setShowReplaceFileModal(true),
      close: () => setShowReplaceFileModal(false),
    },
    relabel: {
      open: () => setShowRelabelModal(true),
      close: () => setShowRelabelModal(false),
    },
    rename: {
      open: () => setShowRenameModal(true),
      close: () => setShowRenameModal(false),
    },
    delete: {
      open: () => setShowDeleteModal(true),
      close: () => setShowDeleteModal(false),
      deleteFunction: handleSoftDeleteFile,
    },
  };

  return (
    <PfaContext.Provider
      value={{
        activeFile,
        setActiveFile,
        modals,
        tableRef,
      }}
    >
      <Container className="mt-3">
        <Row className="justify-content-end mb-3">
          <Col md={2}>
            <Button
              onClick={() => setShowFileUploadModal(true)}
              className="text-capitalize"
            >
              Upload{" "}
              {updatedType === "product"
                ? "Project"
                : updatedType === "ccp"
                  ? "PCM"
                  : updatedType}{" "}
              File
            </Button>
          </Col>
        </Row>
        {updatedType !== "" && (
          <ResponsiveMaterialTable
            title={
              updatedType === "product"
                ? `Project File Area for VID ${product?.v_id}`
                : (updatedType === "ccp"
                    ? "PCM"
                    : capitalizeHyphens(updatedType)) + " File Area"
            }
            columns={columns}
            tableRef={tableRef}
            data={(query) =>
              new Promise((resolve, reject) => {
                // Extract the necessary information from the query object
                const {
                  page,
                  pageSize,
                  search,
                  filters,
                  orderBy,
                  orderDirection,
                } = query;

                let newFilters = filters.map((filter) => {
                  let value = "";
                  if (Array.isArray(filter.value)) {
                    value = filter.value;
                  } else {
                    value = filter.value.replace(/['"]+/g, "");
                  }

                  return `"${filter.column.field.replace(/\./g, "__")}":"${value}"`;
                });
                const params = {
                  offset: page * pageSize,
                  limit: pageSize,
                  search: search,
                  filters: newFilters,
                  orderBy: orderBy?.field,
                  orderDirection: orderDirection,
                  file_type: updatedType,
                  file_type_id: id,
                  show_latest_ear_file: true,
                };

                if (updatedType === "ccp") {
                  params["ccid"] = ccid;
                }

                axios
                  .get(
                    `${process.env.REACT_APP_DJANGO_ENDPOINT}file/${endpoint}?${qs.stringify(
                      params,
                      {
                        arrayFormat: "comma",
                      },
                    )}`,
                    {
                      withCredentials: true,
                      headers: {
                        Authorization: `Token ${authToken}`,
                      },
                    },
                  )
                  .then((response) => {
                    resolve({
                      data: response.data.results,
                      page: page,
                      totalCount: response.data.count,
                    });
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            }
            options={options}
          />
        )}

        {/* Modals */}
        {showFileUploadModal && (
          <FileUploadModal
            show={showFileUploadModal}
            updateFiles={updateFiles}
            type={updatedType}
            id={id}
            ccid={ccid}
            vid={product.v_id}
          />
        )}

        {showReplaceFileModal && (
          <ReplaceFileModal
            show={true}
            setShow={setShowReplaceFileModal}
            updateFiles={updateFiles}
            file={activeFile}
            type={updatedType}
            id={id}
            ccid={ccid}
            vid={product.v_id}
          />
        )}

        {showRenameModal && (
          <RenameObject
            show={true}
            handleClose={modals.rename.close}
            reloadDocuments={reloadDocuments}
            object={activeFile}
          />
        )}

        {showRelabelModal && (
          <RelabelObject
            show={true}
            handleClose={modals.relabel.close}
            reloadDocuments={reloadDocuments}
            object={activeFile}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            show={true}
            handleClose={modals.delete.close}
            type="File"
            deleteFunction={modals.delete.deleteFunction}
            isPermanentDelete={true}
          />
        )}
      </Container>
    </PfaContext.Provider>
  );
};

export default FileTableCheckPermissions;
