import { Typography } from "@mui/material";
import { ComponentType, useEffect, useState } from "react";

import BaseEditableField from "./BaseEditableField";

export interface TEditableModalProps {
  onHide: () => void;
  show: boolean;
}

interface EditableModalProps<
  Value,
  Response,
  TModalProps extends TEditableModalProps,
> {
  title: string;
  value: string;
  onSave: (_update: Value) => Promise<Response>;
  allowEdit: boolean;
  id: string;
  Modal: ComponentType<TModalProps>;
  modalProps?: Omit<TModalProps, "onHide" | "show" | "onSave">;
}

const EditableModal = <
  Value extends unknown,
  Response,
  TModalProps extends TEditableModalProps,
>({
  value,
  onSave,
  allowEdit,
  title,
  Modal,
  modalProps,
}: EditableModalProps<Value, Response, TModalProps>) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!show) return setShow(true);
  }, [show]);
  // HACK: this is not a great way to do this but I needed to keep moving
  // Since the underlying EditableField component drives the display of the modal
  // We need a way to force that to close, so instead of using the show prop directly
  // on the modal we are using this to force the EditableField component to show/hide the modal
  // I'll revisit this when im less crunched

  return (
    <BaseEditableField
      title={title}
      allowEdit={allowEdit}
      value={value}
      onCancel={() => {
        setShow(false);
      }}
      forceClose={!show}
      EditComponent={
        <>
          <Typography>Editing...</Typography>
          <Modal
            {...(modalProps as TModalProps)}
            onHide={() => {
              setShow(false);
            }}
            onSave={(value: Value) => {
              onSave(value);
              setShow(false);
            }}
          />
        </>
      }
    />
  );
};
export default EditableModal;
