import { valueof } from "api/common/utils/type.utils";

export const ECR_STATUSES = {
  DRAFT: "Draft",
  PROPOSED: "Proposed",
  RELEASED: "Released",
  ON_HOLD: "On Hold",
  RESPONSE_TO_VALIDATOR: "Response to Validator",
  RESPONSE_TO_LAB: "Response to Lab",
  CONCEALED: "Concealed",
  PENDING_FINAL_REVIEW: "Pending Final Review",
  REVIEWED: "Reviewed",
  CANCELLED: "Cancelled",
  RESOLVED: "Resolved",
} as const;
export type EcrStatus = valueof<typeof ECR_STATUSES>;

export const ECR_STATES = {
  PROPOSED: "Proposed",
  RELEASED: "Released",
  ON_HOLD: "On Hold",
} as const;
export type EcrState = valueof<typeof ECR_STATES>;

export const ECR_TYPES = {
  ADMINISTRATIVE: "Administrative",
  INFORMATIVE: "Informative",
  SUBSTANTIVE: "Substantive",
  CRITICAL: "Critical",
} as const;
export type EcrType = valueof<typeof ECR_TYPES>;

export const ECR_PROCESS_LOCATIONS = {
  CHECK_IN: "Check In",
  CHECK_OUT: "Check Out",
  ASSURANCE_MAINTENANCE: "Assurance Maintenance",
} as const;
export type EcrProcessLocation = valueof<typeof ECR_PROCESS_LOCATIONS>;
// NOTE: this is more than we have in our dropdown...
export const ECR_FILE_TYPES = {
  ADMINISTRATIVE_GUIDE_AGD: "Administrative Guide (AGD)",
  ASSURANCE_VULNERABILITY_ASSESSMENT_AVA:
    "Assurance Vulnerability Assessment (AVA)",
  ENTROPY_ASSESSMENT_REPORT_EAR: "Entropy Assessment Report (EAR)",
  KEY_MANAGEMENT_DESCRIPTION: "Key Management Description",
  VALIDATION_REPORT: "Validation Report",
  ASSURANCE_ACTIVITY_REPORT_AAR: "Assurance Activity Report (AAR)",
  CHECK_IN_PACKAGE: "Check-in Package",
  EVALUATION_TECHNICAL_REPORT_ETR: "Evaluation Technical Report (ETR)",
  SECURITY_TARGET: "Security Target",
  OTHER: "Other",
  ASSURANCE_CONTINUITY_MAINTENANCE_REPORT_ACMR:
    "Assurance Continuity Maintenance Report (ACMR)",
  CHECK_OUT_PACKAGE: "Check-out Package",
  IMPACT_ANALYSIS_REPORT_IAR: "Impact Analysis Report (IAR)",
  TEST_REPORTS: "Test Reports",
} as const;
export type EcrFileType = valueof<typeof ECR_FILE_TYPES>;

export const ECR_NOTE_RESPONSE_TYPES = {
  LAB: "Lab",
  VALIDATOR: "Validator",
  ECR_CANCELLED: "ECR Cancelled",
} as const;
export type EcrNoteResponseType = valueof<typeof ECR_NOTE_RESPONSE_TYPES>;
