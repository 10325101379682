import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getAuthToken, getCsrfToken } from "utils/cookies";

const fetchBaseQueryWithAuth = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_DJANGO_ENDPOINT,
  credentials: "include",
  prepareHeaders(headers) {
    const authToken = getAuthToken();
    const csrfToken = getCsrfToken();

    if (authToken) {
      headers.set("Authorization", `Token ${authToken}`);
    }

    if (csrfToken) {
      headers.set("X-CSRFToken", csrfToken);
    }

    return headers;
  },
});

// Define a service using a base URL and expected endpoints
export const baseApi = createApi({
  reducerPath: "niapApi",
  // baseUrl is used by default for all requests
  baseQuery: fetchBaseQueryWithAuth,
  endpoints: () => ({}), // We will populate these elsewhere
  tagTypes: [
    "ASSOCIATED_PRODUCTS",
    "ECR",
    "ECR_COMMENTS",
    "ECR_DOCS",
    "ECR_NOTES",
    "ECR_TDS",
    "ECR_TQS",
    "FILE",
    "FILES",
    "MAINTENANCE_BANNER",
    "NIST_COMMENTS",
    "NIST_HISTORY",
    "NIST",
    "NOTE",
    "TD_VOTES",
    "TQ_VOTES",
  ], // If you add a new cache tag you need to add it here first before you can use it
});
