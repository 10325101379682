import { useEffect, useState } from "react";
import Select from "react-select";

import { TypedOption } from "api/common/types/common.types";
import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";

import BaseEditableField from "./BaseEditableField";

interface EditableSelectProps<Value> {
  title: string;
  value: Value;
  onSave: TriggerFunction<Value, Ecr>;
  allowEdit: boolean;
  options: TypedOption<Value>[];
  id: string;
  placeholder: string;
}

const EditableSelect = <Value extends string | number>({
  value,
  onSave,
  allowEdit,
  title,
  options,
  id,
  placeholder,
}: EditableSelectProps<Value>) => {
  const [currentValue, setCurrentValue] = useState<Value>(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <BaseEditableField
      title={title}
      allowEdit={allowEdit}
      value={value}
      EditComponent={
        <Select
          id={id}
          placeholder={placeholder}
          options={options}
          onChange={(e) => {
            if (!e) return;
            setCurrentValue(e.value as Value);
          }}
          defaultValue={options.find(
            (opt: TypedOption<Value>) => opt.value === currentValue,
          )}
        />
      }
      onSave={() => onSave(currentValue)}
      onCancel={() => setCurrentValue(value)}
    />
  );
};
export default EditableSelect;
