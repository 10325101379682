import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import EditableText from "components/DetailsComponents/EditableFields/EditableText";

interface EcrCiteProps {
  userCanEdit: boolean;
  cite: string;
  onSave: TriggerFunction<string, Ecr>;
}

const EcrCiteFieldText = ({ cite, onSave, userCanEdit }: EcrCiteProps) => {
  return (
    <EditableText
      title="Cite"
      value={cite}
      onSave={onSave}
      allowEdit={userCanEdit}
      id="cite"
    />
  );
};
export default EcrCiteFieldText;
