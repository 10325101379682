import { Box, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import {
  ACCESS_LEVELS,
  AUTH_ROLES,
  PERMISSION_AREAS,
} from "api/common/types/auth.types";
import {
  useEcrUpdateMutation,
  useEcrQuery,
} from "api/memberPortal/ecr/ecr.api";
import { EcrUpdatePayload } from "api/memberPortal/ecr/ecr.types";
import {
  useGetAllTdsForEcrQuery,
  useGetAllTqsForEcrQuery,
} from "api/memberPortal/ecr/tds/ecrTds.api";
import EditableHtml from "components/DetailsComponents/EditableFields/EditableHtml";
import UnauthorizedView from "components/UI/UnauthorizedView";
import { useAuth } from "hooks/useAuth";
import { checkPermissions } from "utils/permissions";
import { getISOCurrentDate } from "utils/timeUtils";

import EcrAlert from "../EcrAlert/EcrAlert";
import ECRNoteForm from "../ECRNoteForm";
import ECRNotes from "../ECRNotes";
import ECRSideInfo from "../ECRSideInfo";

import EcrBreadcrumbs from "./components/Ecr.breadcrumbs";
import EcrDetailsEditableFields from "./components/EcrEditableFields";
import EcrProductInfo from "./components/EcrProductInfo";
import EcrRationale from "./components/EcrRationale";
import EcrResponse from "./components/EcrResponse";
import EcrTds from "./components/EcrTds";
import EcrTqs from "./components/EcrTqs";
import EcrTitleField from "./components/EditableFields/EcrTitleField";

export default function EcrDetails() {
  const { currentUser, permissions } = useAuth();
  const { id } = useParams();

  const [alert, setAlert] = useState({ message: "", type: "", show: false });

  const [showNotes, setShowNotes] = useState(false);

  const userIsValidator = permissions.role_type === AUTH_ROLES.VALIDATOR;
  const userIsNiap = permissions.role_type === AUTH_ROLES.NIAP;
  const userCanEdit =
    checkPermissions({
      permissions,
      area: PERMISSION_AREAS.ECR_COMMENTS,
      access: ACCESS_LEVELS.UPDATE,
    }) &&
    (userIsNiap || userIsValidator);

  const { data: ecr } = useEcrQuery(id ?? skipToken);

  const { data: technicalQueries } = useGetAllTqsForEcrQuery(id ?? skipToken);
  const { data: technicalDecisions } = useGetAllTdsForEcrQuery(id ?? skipToken);

  const [triggerUpdateEcr, { isError, isSuccess }] = useEcrUpdateMutation();

  const handleSave = (update: Partial<EcrUpdatePayload>) => {
    if (!ecr) return Promise.reject(new Error("ECR is undefined"));

    return triggerUpdateEcr({
      ecrid: ecr?.ecrid,
      moddate: getISOCurrentDate(),
      editor: currentUser.id,
      ...update,
    });
  };

  useEffect(() => {
    if (isError) {
      setAlert({
        message: "Error Updating Comment.",
        type: "danger",
        show: true,
      });
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setAlert({
        message: "Successfully Updated Comment!",
        type: "success",
        show: true,
      });
    }
  }, [isSuccess]);

  const hasEcrReadPermissions = checkPermissions({
    permissions,
    area: PERMISSION_AREAS.ECR_COMMENTS,
    access: ACCESS_LEVELS.READ,
  });

  if (!hasEcrReadPermissions) {
    return <UnauthorizedView />;
  }

  return (
    <Container className="py-3" fluid>
      <EcrBreadcrumbs ecr={ecr} id={id} />

      <Typography
        variant="h3"
        fontSize={26}
        fontWeight={700}
        marginY={3}
        paddingBottom={0.5}
        borderBottom={"solid 1px rgba(108, 117, 125, 0.25)"}
      >
        ECR COMMENT : {id}
      </Typography>

      <Row>
        <Col sm={12} md={8} lg={9}>
          <Box display="flex" flexWrap="wrap">
            <EcrTitleField
              allowEdit={userCanEdit}
              name={ecr?.gist || ""}
              onSave={handleSave}
            />
            <EcrProductInfo ecr={ecr} />
          </Box>

          <Row>
            <Col sm={12} className="mt-3">
              {alert.show && (
                <EcrAlert
                  type={alert.type}
                  message={alert.message}
                  onClose={() =>
                    setAlert({ type: "", message: "", show: false })
                  }
                />
              )}

              {permissions?.role_type !== "Lab" && (
                <>
                  {/* @ts-ignore - needs noteform updates*/}
                  <ECRNoteForm
                    show={showNotes}
                    handleClose={() => {
                      setShowNotes(false);
                    }}
                    comment={ecr}
                    // fetchNotes={fetchNotes} // TODO: remove from underlying component
                    responseType={"ECR Cancelled"}
                    currentNote={undefined}
                    noteType={undefined}
                    inResponseTo={undefined}
                    setAlert={undefined}
                  />
                </>
              )}
            </Col>

            <EcrDetailsEditableFields key={id} ecr={ecr} onSave={handleSave} />

            <EditableHtml
              title="ECR COMMENT"
              editText="Edit ECR Comment"
              saveText="Save ECR Comment"
              allowEdit={userCanEdit}
              value={ecr?.cmnt || ""}
              onSave={(update) => handleSave({ cmnt: update })}
            />

            <EcrResponse ecr={ecr} />

            <Col sm={12} className="mt-3">
              <EcrTds
                technicalDecisions={technicalDecisions}
                allowEdit={userCanEdit}
                ecr={ecr}
                onSave={handleSave}
              />

              <EcrTqs technicalQueries={technicalQueries} />

              <EcrRationale
                allowEdit={userCanEdit}
                rationale={ecr?.rationale || ""}
                onSave={handleSave}
              />

              {/* @ts-ignore - working on notes */}
              <ECRNotes comment={ecr} />
            </Col>
          </Row>
        </Col>

        <Col sm={12} md={4} lg={3}>
          <ECRSideInfo comment={ecr} id={id} />
        </Col>
      </Row>
    </Container>
  );
}
