import moment from "moment";
import { Button, Modal } from "react-bootstrap";

import { useEcrNoteDeleteMutation } from "api/memberPortal/ecr/notes/ecrNotes.api";
import { EcrNote } from "api/memberPortal/ecr/notes/ecrNotes.types";

interface DeleteModalProps {
  show: boolean;
  toDeleteNote: EcrNote;
  onClose: () => void;
}

const DeleteEcrNoteModal = ({
  show,
  toDeleteNote,
  onClose,
}: DeleteModalProps) => {
  const [triggerDeleteEcrNote] = useEcrNoteDeleteMutation();

  const handleDelete = async () => {
    await triggerDeleteEcrNote(toDeleteNote.upid);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton className="border-0">
        <Modal.Title>Delete Note</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="text-center">
          Are You Sure You Want to Delete this By: <br />
          {toDeleteNote.creator?.first_name} {toDeleteNote.creator?.last_name}{" "}
          on: <br />
          {toDeleteNote.entrydate &&
            moment.utc(toDeleteNote.entrydate).format("YYYY.MM.DD [at] HH:mm")}
        </h4>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" className="m-1" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="danger" className="m-1" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default DeleteEcrNoteModal;
