import { useEffect, useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";

import { useEcrUpdateMutation } from "api/memberPortal/ecr/ecr.api";
import { ECR_NOTE_RESPONSE_TYPES } from "api/memberPortal/ecr/ecrOptions.types";
import {
  useEcrNoteCreateMutation,
  useEcrNoteUpdateMutation,
} from "api/memberPortal/ecr/notes/ecrNotes.api";
import { useAuth } from "hooks/useAuth";
import { getISOCurrentDate } from "utils/timeUtils";

export default function ECRNoteForm({
  show,
  currentNote,
  noteType,
  comment,
  handleClose,
  inResponseTo,
  responseType,
  setAlert,
}) {
  const { currentUser, permissions } = useAuth();
  const [note, setNote] = useState(currentNote);

  const isLab = permissions.role_type === "Lab";
  const isValidator = permissions.role_type === "Validator";

  const handleChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const ecrTitle = () => {
    if (note?.gist) {
      return note?.gist;
    } else if (responseType === "ECR Cancelled") {
      return responseType;
    } else {
      return `Response to ${responseType}`;
    }
  };

  const [triggerUpdateEcr] = useEcrUpdateMutation();
  const updateLabResponse = () => {
    triggerUpdateEcr({
      response: note.note1,
      responseauthor: currentUser.id,
      responsedtg: getISOCurrentDate(),
    });
  };

  const [triggerCreateNote] = useEcrNoteCreateMutation();
  const [triggerUpdateNote] = useEcrNoteUpdateMutation();

  const sendNote = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity()) {
      e.stopPropagation();
    } else {
      const { upid } = note;

      const update = {
        ...note,
        ecrid: comment?.ecrid,
        gist: ecrTitle(),
        pid: comment?.pid?.product_id,
        creator: upid ? note.creator?.id : currentUser?.id,
        entrydate: upid ? note.entrydate : getISOCurrentDate(),
        editor: upid ? currentUser?.id : undefined,
        moddate: upid ? getISOCurrentDate() : undefined,
        in_response_to: inResponseTo,
        response_type: responseType,
        released: note.released ?? responseType === "ECR Cancelled",
      };

      let message = "";
      let variant = "";

      if (upid) {
        const result = await triggerUpdateNote(update);

        if (result.data) {
          message = "Update Successful!";
          variant = "success";
        }

        if (result.error) {
          message = "Error updating note. Please try again.";
          variant = "danger";
        }
      } else {
        const result = await triggerCreateNote(update);

        if (result.data) {
          message = "Successfully Created Note!";
          variant = "success";
        }

        if (result.error) {
          message = "Error creating note. Please try again.";
          variant = "danger";
        }
        handleClose();
      }

      if (responseType === ECR_NOTE_RESPONSE_TYPES.VALIDATOR) {
        updateLabResponse();
      }

      setAlert({
        message: message,
        variant: variant,
      });
      handleClose();
    }
  };

  const noteTitle = () => {
    switch (noteType) {
      case "response":
        return "ADD RESPONSE for";
      case "initiate":
        return "INITIATE";
      default:
        return `${note?.upid ? "EDIT" : "ADD"} NOTE for`;
    }
  };

  useEffect(() => {
    if (!show) {
      setNote({});
      return;
    }
    if (noteType === "response") {
      setNote({ ...currentNote, category: "Response to Validator" });
    } else {
      setNote({
        ...currentNote,
        private: currentNote?.private ?? false,
        visible_to_labs: currentNote?.visible_to_labs ?? true,
        visible_to_validators: currentNote?.visible_to_validators ?? true,
      });
    }
  }, [noteType, currentNote]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Form onSubmit={sendNote} key={note?.upid}>
        <Modal.Header closeButton>
          <Modal.Title>+ {noteTitle()} ECR COMMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {responseType === "ECR Cancelled" && (
            <Alert variant="danger">
              You must add a note to save a status of cancelled
            </Alert>
          )}
          <h2>Details for VID {comment?.pid?.v_id}</h2>
          {permissions?.role_type === "NIAP" && (
            <Form.Group className="my-3">
              <Form.Label>Is Private?</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  name="private"
                  value={true}
                  inline
                  label="Yes"
                  onChange={handleChange}
                  checked={note?.private === "true" || note?.private === true}
                  data-testid="private1"
                  required
                />
                <Form.Check
                  type="radio"
                  name="private"
                  value={false}
                  inline
                  label="No"
                  onChange={handleChange}
                  checked={note?.private === "false" || note?.private === false}
                  data-testid="private2"
                  required
                />
              </div>
            </Form.Group>
          )}
          {(note?.private === "false" || note?.private === false) && (
            <>
              {!isLab && (
                <Form.Group className="mb-3" controlId="role_type">
                  <Form.Label className="me-3">Visible To Labs?*</Form.Label>
                  <Form.Check
                    type="radio"
                    name="visible_to_labs"
                    value={true}
                    inline
                    label="Yes"
                    onChange={handleChange}
                    checked={
                      note?.visible_to_labs === "true" ||
                      note?.visible_to_labs === true
                    }
                    data-testid="visible_to_labs1"
                    required
                  />
                  <Form.Check
                    type="radio"
                    name="visible_to_labs"
                    value={false}
                    inline
                    label="No"
                    onChange={handleChange}
                    checked={
                      note?.visible_to_labs === "false" ||
                      note?.visible_to_labs === false
                    }
                    data-testid="visible_to_labs2"
                    required
                  />
                </Form.Group>
              )}
              {!isValidator && (
                <Form.Group className="mb-3" controlId="role_type">
                  <Form.Label className="me-3">
                    Visible To Validators?*
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    name="visible_to_validators"
                    value={true}
                    inline
                    label="Yes"
                    onChange={handleChange}
                    checked={
                      note?.visible_to_validators === "true" ||
                      note?.visible_to_validators === true
                    }
                    data-testid="visible_to_validators1"
                    required
                  />
                  <Form.Check
                    type="radio"
                    name="visible_to_validators"
                    value={false}
                    inline
                    label="No"
                    onChange={handleChange}
                    checked={
                      note?.visible_to_validators === "false" ||
                      note?.visible_to_validators === false
                    }
                    data-testid="visible_to_validators2"
                    required
                  />
                </Form.Group>
              )}
            </>
          )}

          <Form.Group className="mb-3" controlId="title">
            <Form.Label className="fw-bold">Title of Note:</Form.Label>
            <Form.Control
              rows={8}
              name="gist"
              onChange={handleChange}
              placeholder="Title"
              defaultValue={ecrTitle()}
              disabled={noteType === "response"}
              // required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="overview">
            <Form.Label className="fw-bold">Overview*</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="note1"
              onChange={handleChange}
              placeholder="Overview"
              value={note?.note1 ? note.note1 : ""}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            type="button"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
