import { Box } from "@mui/material";

import {
  ACCESS_LEVELS,
  AUTH_ROLES,
  PERMISSION_AREAS,
} from "api/common/types/auth.types";
import { TriggerFunction } from "api/common/types/response.types";
import { Ecr, EcrUpdatePayload } from "api/memberPortal/ecr/ecr.types";
import { useAuth } from "hooks/useAuth";
import { checkPermissions } from "utils/permissions";

import EcrCiteFieldText from "./EditableFields/EcrCiteFieldText";
import EcrDocumentsField from "./EditableFields/EcrDocumentsField";
import EcrPpsField from "./EditableFields/EcrPpsField";
import EcrRelatesToFieldSelect from "./EditableFields/EcrRelatesToFieldSelect";
import EcrRequirementFieldText from "./EditableFields/EcrRequirementFieldText";
import EcrStatusFieldSelect from "./EditableFields/EcrStatusFieldSelect";
import EcrTypeFieldSelect from "./EditableFields/EcrTypeFieldSelect";

interface EcrEditableFieldsProps {
  ecr?: Ecr;
  onSave: TriggerFunction<Partial<EcrUpdatePayload>, Ecr>;
}

const EcrDetailsEditableFields = ({ ecr, onSave }: EcrEditableFieldsProps) => {
  const { permissions } = useAuth();

  const userIsValidator = permissions.role_type === AUTH_ROLES.VALIDATOR;
  const userIsNiap = permissions.role_type === AUTH_ROLES.NIAP;
  const userCanEdit =
    checkPermissions({
      permissions,
      area: PERMISSION_AREAS.ECR_COMMENTS,
      access: ACCESS_LEVELS.UPDATE,
    }) &&
    (userIsNiap || userIsValidator);

  if (!ecr) return null;

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      borderBottom={"solid rgba(108, 117, 125, 0.25) 1px"}
      borderTop={"solid rgba(108, 117, 125, 0.25) 1px"}
      padding=".5em"
    >
      <EcrStatusFieldSelect
        status={ecr.status}
        allowEdit={userCanEdit}
        onSave={(update) => onSave({ status: update })}
      />

      <EcrPpsField
        ecr={ecr}
        onSave={(update) => onSave({ pp_id: update })}
        allowEdit={userCanEdit}
      />

      <EcrTypeFieldSelect
        allowEdit={userCanEdit}
        onSave={(update) => onSave({ type: update })}
        type={ecr.type}
      />

      <EcrDocumentsField userCanEdit={userCanEdit} ecrid={ecr.ecrid} />

      <EcrCiteFieldText
        userCanEdit={userCanEdit}
        cite={ecr.cite}
        onSave={(update) => onSave({ cite: update })}
      />

      <EcrRelatesToFieldSelect
        allowEdit={userCanEdit}
        processLocation={ecr.process_location}
        onSave={(update) => onSave({ process_location: update })}
      />

      <EcrRequirementFieldText
        allowEdit={userCanEdit}
        requirement={ecr.req}
        onSave={(update) => onSave({ req: update })}
      />
    </Box>
  );
};
export default EcrDetailsEditableFields;
