import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";
import EditableText from "components/DetailsComponents/EditableFields/EditableText";

interface EcrCiteProps {
  allowEdit: boolean;
  requirement: string;
  onSave: TriggerFunction<string, Ecr>;
}

const EcrRequirementFieldText = ({
  requirement,
  onSave,
  allowEdit,
}: EcrCiteProps) => {
  return (
    <EditableText
      title="Requirement"
      value={requirement}
      onSave={onSave}
      allowEdit={allowEdit}
      id="requirement"
    />
  );
};
export default EcrRequirementFieldText;
