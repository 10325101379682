import { Box, Typography } from "@mui/material";

import { Ecr } from "api/memberPortal/ecr/ecr.types";

interface EcrProductInfoProps {
  ecr?: Ecr;
}

const EcrProductInfo = ({ ecr }: EcrProductInfoProps) => {
  if (!ecr) return;

  return (
    <Box
      flex="1 1  45%"
      maxWidth="45%"
      paddingLeft={2}
      borderLeft="1px solid #6c757d"
      sx={{
        flex: {
          md: "1 1 50%",
          sm: "1 1 100%",
        },
        maxWidth: {
          md: "calc(50% - 17px)", // 2 * & Margin X + 1 Left Border
          sm: "100%",
        },
        margin: 1,
        paddingLeft: 1,
        marginLeft: 0,
      }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        fontSize="16px"
        color="textPrimary"
        fontStyle="italic"
      >
        Product Info
      </Typography>

      <Typography
        variant="h3"
        fontWeight="bold"
        fontSize="26px"
        color="textPrimary"
      >
        {ecr.pid?.product_name}
      </Typography>

      <p>
        Creator: {ecr.creator?.first_name} {ecr.creator?.last_name}
      </p>
    </Box>
  );
};
export default EcrProductInfo;
