import { TextField } from "@mui/material";
import { useState } from "react";

import { TriggerFunction } from "api/common/types/response.types";
import { Ecr } from "api/memberPortal/ecr/ecr.types";

import BaseEditableField from "./BaseEditableField";

interface EditableSelectProps {
  title: string;
  value: string;
  onSave: TriggerFunction<string, Ecr>;
  allowEdit: boolean;
  id: string;
}

const EditableText = ({
  value,
  onSave,
  allowEdit,
  title,
  id,
}: EditableSelectProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  return (
    <BaseEditableField
      title={title}
      allowEdit={allowEdit}
      value={value}
      onSave={() => onSave(currentValue)}
      onCancel={() => setCurrentValue(value)}
      EditComponent={
        <TextField
          id={id}
          variant="filled"
          fullWidth
          margin="none"
          size="small"
          hiddenLabel
          onChange={(e) => setCurrentValue(e.target.value)}
          value={currentValue}
        />
      }
    />
  );
};
export default EditableText;
