import { skipToken } from "@reduxjs/toolkit/query";
import parse from "html-react-parser";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Accordion, Alert, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useEcrUpdateMutation } from "api/memberPortal/ecr/ecr.api";
import { ECR_STATUSES } from "api/memberPortal/ecr/ecrOptions.types";
import {
  useEcrNotesQuery,
  useReleaseEcrResponseMutation,
} from "api/memberPortal/ecr/notes/ecrNotes.api";
import { useAuth } from "hooks/useAuth";

import ECRNoteForm from "./ECRNoteForm";
import "moment-timezone";
import DeleteEcrNoteModal from "./EcrNotes/components/DeleteEcrNote.modal";

const emptyArray = [];

export default function ECRNotes({ comment }) {
  // TODO fetchComment REPLACEMENT
  const { id } = useParams();

  const [showNotes, setShowNotes] = useState(false);
  const [currentNote, setCurrentNote] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [inResponseTo, setInResponseTo] = useState();
  const [totalNotes, setTotalNotes] = useState(0);
  const [nestedNotes, setNestedNotes] = useState([]);
  const [responseType, setResponseType] = useState("");
  const [expandAll, setExpandAll] = useState(false);
  const [toDeleteNote, setToDeleteNote] = useState();
  const [alert, setAlert] = useState({ message: "", variant: "" });
  const { currentUser, permissions } = useAuth();

  const { data: notes = emptyArray } = useEcrNotesQuery(id || skipToken);

  const openNoteModal = (replyTo = null, note = null) => {
    if (note?.in_response_to) {
      setInResponseTo(note?.in_response_to.upid);
    } else {
      setInResponseTo(replyTo);
    }
    setCurrentNote(note);
    setShowNotes(true);
  };

  const openDeleteModal = (note) => {
    setToDeleteNote(note);
    setShowDeleteModal(true);
  };

  const [selectedNotes, setSelectedNotes] = useState([]);

  useEffect(() => {
    setAlert({});
  }, [comment?.ecrid]);

  useEffect(() => {
    if (permissions?.role_type === "Lab") {
      setResponseType("Validator");
    } else {
      setResponseType("Lab");
    }
  }, [permissions]);

  useEffect(() => {
    const unfilteredNotes = notes;
    if (!unfilteredNotes) {
      setTotalNotes(0);
      setNestedNotes([]);
      return;
    }
    setTotalNotes(unfilteredNotes.length);
    const filteredNotes = [];
    const responses = {};
    unfilteredNotes.forEach((note) => {
      if (!note.in_response_to) {
        filteredNotes.push({ ...note });
      } else {
        if (!responses[note.in_response_to.upid]) {
          responses[note.in_response_to.upid] = [];
        }
        responses[note.in_response_to.upid].push({ ...note });
      }
    });
    for (let key in responses) {
      const children = responses[key];
      children.forEach((note, idx) => {
        if (responses[note.upid]) {
          note["responses"] = responses[note.upid];
          responses[key][idx] = note;
        }
      });
    }
    filteredNotes.forEach((note) => {
      if (responses[note.upid]) {
        note["responses"] = responses[note.upid];
      }
    });
    setNestedNotes(filteredNotes);
  }, [notes]);

  const noteStatus = (note) => {
    if (note?.response_type === "ECR Cancelled") {
      return note?.response_type;
    } else if (note?.released) {
      return `Released to ${note?.response_type}`;
    } else {
      return `Not Released to ${note?.response_type}`;
    }
  };
  const visibilityText = (note) => {
    if (note.private) {
      return "Private";
    } else if (note.visible_to_labs && note.visible_to_validators) {
      return "Visible to Lab and Validators";
    } else if (note.visible_to_labs) {
      return "Visible to Labs";
    } else if (note.visible_to_validators) {
      return "Visible to Validators";
    } else {
      return "N/A";
    }
  };

  const childNotes = (note) => {
    return (
      <>
        <div className="text-end mx-3">
          <button
            className="btn btn-link d-inline-block py-1"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.upid)}
          >
            Reply
          </button>
          {note?.creator?.id === currentUser?.id && (
            <>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => {
                  openNoteModal(null, note);
                }}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 ms-3 mt-0">
          <p className="text-end">
            Note by: {note.creator?.first_name} {note.creator?.last_name} <br />
            {note.entrydate &&
              moment(note.entrydate).format("YYYY.MM.DD [at] HH:mm")}
            <br />
            {visibilityText(note) === "N/A" ? (
              <span>Note status: {noteStatus(note)}</span>
            ) : (
              <span>Visibility: {visibilityText(note)} </span>
            )}
          </p>
          <h6>{note.gist}</h6>
          <div>{note.note1 && parse(note.note1)}</div>
          {note.responses && (
            <Accordion
              defaultActiveKey={note.upid}
              activeKey={selectedNotes}
              onSelect={() => {
                const uniqueNotes = new Set(selectedNotes);
                if (uniqueNotes.has(note.upid)) {
                  uniqueNotes.delete(note.upid);
                } else {
                  uniqueNotes.add(note.upid);
                }
                setSelectedNotes([...uniqueNotes]);
              }}
            >
              <Accordion.Item
                eventKey={selectedNotes.includes(note.upid) ? note.upid : null}
              >
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.upid}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const displayNotes = () => {
    return nestedNotes.map((note) => (
      <div key={note.upid}>
        <div className="text-end mx-3">
          <button
            className="btn btn-link d-inline-block py-1"
            style={{ minWidth: 10 }}
            onClick={() => openNoteModal(note.upid)}
          >
            Reply
          </button>
          {note?.creator?.id === currentUser?.id && (
            <>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal(null, note)}
              >
                Edit
              </button>
              <button
                className="btn btn-link d-inline-block py-1"
                style={{ minWidth: 10 }}
                onClick={() => openDeleteModal(note)}
              >
                Delete
              </button>
            </>
          )}
        </div>
        <div className="border border-secondary p-4 m-4 mt-0">
          <p className="text-end">
            Note by: {note.creator?.first_name} {note.creator?.last_name} <br />
            {note.entrydate &&
              moment(note.entrydate).format("YYYY.MM.DD [at] HH:mm")}
            <br />
            {visibilityText(note) === "N/A" ? (
              <span>Note status: {noteStatus(note)}</span>
            ) : (
              <span>Visibility: {visibilityText(note)} </span>
            )}
          </p>
          <h6>{note.gist}</h6>
          <div>{note.note1 && parse(note.note1)}</div>
          {note.responses && (
            <Accordion
              defaultActiveKey={note.upid}
              activeKey={selectedNotes}
              onSelect={() => {
                const uniqueNotes = new Set(selectedNotes);
                if (uniqueNotes.has(note.upid)) {
                  uniqueNotes.delete(note.upid);
                } else {
                  uniqueNotes.add(note.upid);
                }
                setSelectedNotes([...uniqueNotes]);
              }}
            >
              <Accordion.Item
                eventKey={selectedNotes.includes(note.upid) ? note.upid : null}
              >
                <Accordion.Header>
                  {note.responses.length} Responses
                </Accordion.Header>
                <Accordion.Body>
                  {note.responses?.map((child) => (
                    <div key={child.upid}>{childNotes(child)}</div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    ));
  };

  const [triggerReleaseResponse] = useReleaseEcrResponseMutation();
  const [triggerUpdateEcr] = useEcrUpdateMutation();

  const releaseResponses = async () => {
    if (!comment) return;

    const result2 = await triggerReleaseResponse({
      response_type: responseType,
      product: comment.pid?.product_id,
      maintenance: comment.maintenance_id?.maintenance_id,
    });

    const result = await triggerUpdateEcr({
      ecrid: comment.ecrid,
      status:
        responseType === "Lab"
          ? ECR_STATUSES.RESPONSE_TO_LAB
          : ECR_STATUSES.RESPONSE_TO_VALIDATOR,
    });

    if (result.error) {
      setAlert({
        message: "Error releasing responses. Please try again.",
        variant: "success",
      });
    }

    if (result.data) {
      setAlert({
        message: `Responses Released! ECR Status set as Response to ${responseType}`,
        variant: "success",
      });
    }
  };

  return (
    <Accordion flush>
      {alert?.message && (
        <Alert
          className="mt-3"
          variant={alert.variant}
          onClose={() => setAlert({})}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Accordion.Item>
        <Accordion.Header>
          <h6 className="fw-bold text-dark">NOTES</h6>
          <span className="border my-2 mx-4 p-2">Total: {totalNotes}</span>
        </Accordion.Header>
        {(permissions?.role_type === "Lab" ||
          permissions?.is_senior ||
          permissions?.is_lead) &&
          permissions?.role_type !== "NIAP" && (
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  variant="warning rounded-pill m-1"
                  onClick={() => openNoteModal()}
                >
                  {permissions?.role_type === "Lab"
                    ? "Respond to Validator"
                    : "Response to Lab"}
                </Button>
                <Button
                  variant="warning rounded-pill m-1"
                  onClick={releaseResponses}
                >
                  Release to {responseType}
                </Button>
              </Col>
            </Row>
          )}
        <Accordion.Body>
          {
            <div className="text-end">
              <button
                className="btn btn-link d-inline-block"
                style={{ minWidth: 10 }}
                onClick={() => openNoteModal()}
              >
                Add
              </button>
            </div>
          }
          {notes?.length > 0 &&
            (!expandAll ? (
              <div>
                <button
                  className="btn btn-primary d-inline-block"
                  style={{ minWidth: 10 }}
                  onClick={() => {
                    setExpandAll(true);
                    setSelectedNotes(notes.map((note) => note.upid));
                  }}
                >
                  Expand All
                </button>
              </div>
            ) : (
              <div>
                <button
                  className="btn btn-primary d-inline-block"
                  style={{ minWidth: 10 }}
                  onClick={() => {
                    setExpandAll(false);
                    setSelectedNotes([]);
                  }}
                >
                  Collapse All
                </button>
              </div>
            ))}
          {notes?.length > 0 && displayNotes()}
          <ECRNoteForm
            show={showNotes}
            handleClose={() => {
              setCurrentNote({});
              setShowNotes(false);
              // fetchComment();
            }}
            currentNote={currentNote}
            comment={comment}
            inResponseTo={inResponseTo}
            responseType={responseType}
            setAlert={setAlert}
          />
          {showDeleteModal && (
            <DeleteEcrNoteModal
              toDeleteNote={toDeleteNote}
              onClose={() => setShowDeleteModal(false)}
              show={showDeleteModal}
            />
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
